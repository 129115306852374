import React, { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'components/Button';

function LoginButton() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return null;
  }

  return (
    <Button onClick={() => loginWithRedirect()} type="button" maxWidth="200px">
      Sign In
    </Button>
  );
}

export default LoginButton;
