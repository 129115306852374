import React from 'react';
import { Container, Navbar, Heading, Headline, Text } from 'components';
import { Flex, Text as ChakraText } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function CookiePolicy() {
  return (
    <div>
      <Navbar top />
      <Container>
        <Heading>Cookie Policy</Heading>
        <Headline>Updated at 2022-05-26</Headline>
        <Headline>Definitions and key terms</Headline>
        <Text>
          To help explain things as clearly as possible in this Cookie Policy, every time any of
          these terms are referenced, are strictly defined as:
        </Text>
        <Text>
          -Cookie: small amount of data generated by a website and saved by your web browser. It is
          used to identify your browser, provide analytics, remember information about you such as
          your language preference or login information.
          <br />
          -Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to
          Spacebridge.ai, that is responsible for your information under this Cookie Policy.
          <br />
          -Device: any internet connected device such as a phone, tablet, computer or any other
          device that can be used to visit Spacebridge.ai and use the services.
          <br />
          -Personal Data: any information that directly, indirectly, or in connection with other
          information — including a personal identification number — allows for the identification
          or identifiability of a natural person.
          <br />
          -Service: refers to the service provided by Spacebridge.ai as described in the relative
          terms (if available) and on this platform.
          <br />
          -Third-party service: refers to advertisers, contest sponsors, promotional and marketing
          partners, and others who provide our content or whose products or services we think may
          interest you.
          <br />
          -Website: Spacebridge.ai.’s site, which can be accessed via this URL:
          https://spacebridge.ai/
          <br />
          -You: a person or entity that is registered with Spacebridge.ai to use the Services.
        </Text>
        <Headline>Introduction</Headline>
        <Text>
          This Cookie Policy explains how Spacebridge.ai and its affiliates (collectively
          &quot;Spacebridge.ai&quot;, &quot;we&quot;, &quot;us&quot;, and &quot;ours&quot;), use
          cookies and similar technologies to recognize you when you visit our website/app,
          including without limitation https://spacebridge.ai/ and any related URLs, mobile or
          localized versions and related domains / sub-domains (&quot;Websites&quot;). It explains
          what these technologies are and why we use them, as well as the choices for how to control
          them.
        </Text>
        <Headline>What is a cookie?</Headline>
        <Text>
          A cookie is a small text file that is stored on your computer or other internet connected
          device in order to identify your browser, provide analytics, remember information about
          you such as your language preference or login information. They&apos;re completely safe
          and can&apos;t be used to run programs or deliver viruses to your device.
        </Text>
        <Headline>Why do we use cookies?</Headline>
        <Text>
          We use first party and/or third party cookies on our website/app for various purposes such
          as:
        </Text>
        <Text>
          -To facilitate the operation and functionality of our website/app;
          <br />
          -To improve your experience of our website/app and make navigating around them quicker and
          easier;
          <br />
          -To allow us to make a bespoke user experience for you and for us to understand what is
          useful or of interest to you;
          <br />
          -To analyze how our website/app is used and how best we can customize it;
          <br />
          -To identify future prospects and personalize marketing and sales interactions with it;
          <br />
          -To facilitate the tailoring of online advertising to your interests.
        </Text>
        <Headline>What type of cookies does Spacebridge.ai use?</Headline>
        <Text>
          Cookies can either be session cookies or persistent cookies. A session cookie expires
          automatically when you close your browser. A persistent cookie will remain until it
          expires or you delete your cookies. Expiration dates are set in the cookies themselves;
          some may expire after a few minutes while others may expire after multiple years. Cookies
          placed by the website you’re visiting are called “first party cookies”.
        </Text>
        <Text>
          Strictly Necessary cookies are necessary for our website/app to function and cannot be
          switched off in our systems. They are essential in order to enable you to navigate around
          the website/app and use its features. If you remove or disable these cookies, we cannot
          guarantee that you will be able to use our website/app.
        </Text>
        <Text>We use the following types of cookies in our website/app:</Text>
        <Headline>Errors and Omissions Disclaimer</Headline>
        <Text>
          Spacebridge.ai is not responsible for any content, code or any other imprecision.
        </Text>
        <Text>Spacebridge.ai does not provide warranties or guarantees.</Text>
        <Text>
          In no event shall Spacebridge.ai be liable for any special, direct, indirect,
          consequential, or incidental damages or any damages whatsoever, whether in an action of
          contract, negligence or other tort, arising out of or in connection with the use of the
          Service or the contents of the Service. Spacebridge.ai reserves the right to make
          additions, deletions, or modifications to the contents on the Service at any time without
          prior notice.
        </Text>
        <Headline>General Disclaimer</Headline>
        <Text>
          The Spacebridge.ai Service and its contents are provided &quot;as is&quot; and &quot;as
          available&quot; without any warranty or representations of any kind, whether express or
          implied. Spacebridge.ai is a distributor and not a publisher of the content supplied by
          third parties; as such, Spacebridge.ai exercises no editorial control over such content
          and makes no warranty or representation as to the accuracy, reliability or currency of any
          information, content, service or merchandise provided through or accessible via the
          Spacebridge.ai Service. Without limiting the foregoing, Spacebridge.ai specifically
          disclaims all warranties and representations in any content transmitted on or in
          connection with the Spacebridge.ai Service or on sites that may appear as links on the
          Spacebridge.ai Service, or in the products provided as a part of, or otherwise in
          connection with, the Spacebridge.ai Service, including without limitation any warranties
          of merchantability, fitness for a particular purpose or non-infringement of third party
          rights. No oral advice or written information given by Spacebridge.ai or any of its
          affiliates, employees, officers, directors, agents, or the like will create a warranty.
          Price and availability information is subject to change without notice. Without limiting
          the foregoing, Spacebridge.ai does not warrant that the Spacebridge.ai Service will be
          uninterrupted, uncorrupted, timely, or error-free.
        </Text>
        <Headline>Marketing Cookies</Headline>
        <Text>
          These account-based marketing cookies enable us to identify future prospects and
          personalize sales and marketing interactions with them.
        </Text>
        <Headline>Analytics and Customization Cookies</Headline>
        <Text>
          These cookies collect information that is used to help us understand how our website/app
          is being used or how effective our marketing campaigns are, or to help us customize our
          website/app for you.
        </Text>
        <Text>
          We use cookies served by Google Analytics to collect limited data directly from end-user
          browsers to enable us to better understand your use of our website/app. Further
          information on how Google collects and uses this data can be found at:
          https://www.google.com/policies/privacy/partners/. You can opt-out of all Google supported
          analytics on our Websites by visiting: https://tools.google.com/dlpage/gaoptout.
        </Text>
        <Headline>Third Party Cookies</Headline>
        <Text>
          Some cookies that have been set on our website/app are not set on a first party basis by
          Spacebridge.ai. The Websites can be embedded with content from third parties to serve
          advertising. These third party service providers may set their own cookies on your web
          browser. Third party service providers control many of the performance and functionality,
          advertising, marketing and analytics cookies described above. We do not control the use of
          these third party cookies as cookies can only be accessed by the third party that
          originally set them.
        </Text>
        <Headline>How you can manage cookies?</Headline>
        <Text>
          Most browsers allow you to control cookies through their &apos;settings&apos; preferences.
          However, if you limit the ability of websites to set cookies, you may worsen your overall
          user experience, since it will no longer be personalized to you. It may also stop you from
          saving customized settings like login information. Browser manufacturers provide help
          pages relating to cookie management in their products.
        </Text>
        <Text>
          Browser manufacturers provide help pages relating to cookie management in their products.
          Please see below for more information.
        </Text>
        <Text>
          {' '}
          -Google Chrome
          <br />
          -Internet Explorer
          <br />
          -Mozilla Firefox
          <br />
          -Safari (Desktop)
          <br />
          -Safari (Mobile)
          <br />
          -Android Browser
          <br />
          -Opera
          <br />
          -Opera Mobile
        </Text>
        <Headline>Blocking and disabling cookies and similar technologies</Headline>
        <Text>
          Wherever you&apos;re located you may also set your browser to block cookies and similar
          technologies, but this action may block our essential cookies and prevent our website from
          functioning properly, and you may not be able to fully utilize all of its features and
          services. You should also be aware that you may also lose some saved information (e.g.
          saved login details, site preferences) if you block cookies on your browser. Different
          browsers make different controls available to you. Disabling a cookie or category of
          cookie does not delete the cookie from your browser, you will need to do this yourself
          from within your browser, you should visit your browser&apos;s help menu for more
          information.
        </Text>
        <Headline>Changes To Our Cookie Policy</Headline>
        <Text>
          We may change our Service and policies, and we may need to make changes to this Cookie
          Policy so that they accurately reflect our Service and policies. Unless otherwise required
          by law, we will notify you (for example, through our Service) before we make changes to
          this Cookie Policy and give you an opportunity to review them before they go into effect.
          Then, if you continue to use the Service, you will be bound by the updated Cookie Policy.
          If you do not want to agree to this or any updated Cookie Policy, you can delete your
          account.
        </Text>
        <Headline>Your Consent</Headline>
        <Text>
          By using our website/app, registering an account, or making a purchase, you hereby consent
          to our Cookie Policy and agree to its terms.
        </Text>
        <Headline>Contact Us</Headline>
        <Text>
          Don&apos;t hesitate to contact us if you have any questions regarding our Cookie Policy.
          <br />
          -Via Email: office@spacebridge.ai
          <br />
          -Via this Address: Office 517b 182-184 High Street North, London, Greater London, United
          Kingdom, E6 2JA
        </Text>
        <Flex paddingBottom="20px" marginTop="114px">
          <ChakraText marginRight="24px" fontSize="13px">
            <Link to="/privacy-policy">Privacy Policy</Link>
          </ChakraText>
          <ChakraText marginRight="24px" fontSize="13px">
            <Link to="/terms-of-use">Terms of use</Link>
          </ChakraText>
          <ChakraText fontSize="13px">
            <Link to="/cookies-policy">Cookies Policy</Link>
          </ChakraText>
        </Flex>
      </Container>
    </div>
  );
}

export default CookiePolicy;
