/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { Flex, Select } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { Container } from 'components';
import background from 'assets/backgrounds/dots.svg';
import SpacebridgeFooter from 'components/SpacebridgeFooter';
import { TPersonalPreferences } from 'types/general.types';
import { changeAnswers } from 'store/rootReducer/reducer';
import {
  StyledMobileBig,
  StyledBackgroundMobileBig,
  StyledPyramidMobileBig,
  StyledResultMobileBig,
} from './styles';

export default function MobileQsortingBig({
  currentQsortingData,
  answers,
}: {
  currentQsortingData: TPersonalPreferences;
  answers: any;
}) {
  const dispatch = useDispatch();
  const { name } = currentQsortingData;
  const boxCounter = 14;

  const handleSelectChange = (item: any, id: number) => {
    dispatch(
      changeAnswers({
        name: `q-${name}`,
        value: {
          destination: id,
          element: item.target.value,
        },
      }),
    );
  };

  return (
    <StyledMobileBig>
      <StyledBackgroundMobileBig background={background}>
        <StyledPyramidMobileBig>
          {Array.from({ length: boxCounter }, (_, x) => (
            <div key={x} className={`box square div${x + 1}`}>
              {answers[x + 1][0] ? (
                <StyledResultMobileBig>{answers[x + 1][0].name}</StyledResultMobileBig>
              ) : (
                <Select
                  placeholder="Select option"
                  m={1}
                  size="xs"
                  onChange={(item) => handleSelectChange(item, x + 1)}
                >
                  {answers[0].map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              )}
            </div>
          ))}
        </StyledPyramidMobileBig>
        <Container small>
          <Flex justifyContent="space-between" mt="40px">
            <Flex color="#5431B7" fontSize="13px" fontWeight="600">
              LESS IMPORTANT
            </Flex>
            <Flex color="#5431B7" fontSize="13px" fontWeight="600">
              IMPORTANT
            </Flex>
            <Flex color="#5431B7" fontSize="13px" fontWeight="600">
              VERY IMPORTANT
            </Flex>
          </Flex>
        </Container>
        <SpacebridgeFooter />
      </StyledBackgroundMobileBig>
    </StyledMobileBig>
  );
}
