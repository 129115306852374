import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Progress } from '@chakra-ui/react';
import { Container } from 'components';
import logo from 'assets/brand/logo.svg';
import logoWhite from 'assets/brand/logo-white.svg';
import useWindowDimensions from 'common/hooks';
import { StyledNavbar } from './styles';
import Items from './items';

export function Navbar({
  children,
  home,
  top,
  black,
  nav,
  currentStep,
  navbarImage,
  progressValue,
}: {
  children?: React.ReactNode;
  home?: boolean;
  top?: boolean;
  black?: boolean;
  nav?: boolean;
  currentStep?: number;
  navbarImage?: string;
  progressValue?: number;
}) {
  const size = useWindowDimensions();
  return (
    <>
      <StyledNavbar home={home} top={top} black={black} custom={Boolean(navbarImage)}>
        <Container nav home={home}>
          <Flex
            justifyContent="space-between"
            p={{ base: '15px', lg: 0 }}
            px={{ base: '15px', lg: '24px' }}
          >
            <Link to="/" className="logo-link">
              <img src={navbarImage || (black ? logoWhite : logo)} alt="logo" />
            </Link>
            {nav && size.width > 650 ? <Items currentStep={currentStep || 0} /> : children}
          </Flex>
        </Container>
      </StyledNavbar>
      {!black && <Progress className="progress" size="xs" value={progressValue} />}
    </>
  );
}

Navbar.defaultProps = {
  home: false,
  top: false,
  nav: false,
  black: false,
  children: '',
  currentStep: 0,
  navbarImage: '',
  progressValue: 0,
};
