import React from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import * as RoutesList from 'common/routes';

export function DevRouting({ testAction }: { testAction?: () => void }) {
  const currentRoute = window.location.href;

  return currentRoute.includes('localhost') || currentRoute.includes('dev.app.spacebridge.ai') ? (
    <Accordion position="fixed" bottom="100px" right="0" borderColor="#fff" zIndex="9999">
      <AccordionItem>
        <h2>
          <AccordionButton data-testid="button-routing">
            <Box flex="1" textAlign="left">
              Dev routing
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel data-testid="accordion-link">
          <Flex flexDirection="column">
            <Link to={RoutesList.NAME}>Name</Link>
            <Link to={RoutesList.TECH}>Technologies</Link>
            <Link to={RoutesList.STRENGTHS_SCREEN}>Strengths screen</Link>
            <Link to={RoutesList.TEAM1}>Strengths 1</Link>
            <Link to={RoutesList.TEAM2}>Strengths 2</Link>
            <Link to={RoutesList.TEAM3}>Strengths 3</Link>
            <Link to={RoutesList.TEAM4}>Strengths 4</Link>
            <Link to={RoutesList.PREFERENCES_SCREEN}>Preferences screen</Link>
            <Link to={RoutesList.QSORT1}>Preferences 1</Link>
            <Link to={RoutesList.QSORT2}>Preferences 2</Link>
            <Link to={RoutesList.QSORT3}>Preferences 3</Link>
            <Link to={RoutesList.QSORT4}>Preferences 4</Link>
            <Link to={RoutesList.THANKS}>Thank you</Link>
            <Button onClick={testAction} data-testid="action-button">Test action</Button>
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  ) : null;
}

DevRouting.defaultProps = {
  testAction: () => {},
};
