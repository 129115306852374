import React from 'react';
import { Flex, Select } from '@chakra-ui/react';
import { Container } from 'components';
import background from 'assets/backgrounds/dots.svg';
import SpacebridgeFooter from 'components/SpacebridgeFooter';
import { TPersonalPreferences } from 'types/general.types';
import { useDispatch } from 'react-redux';
import { changeAnswers } from 'store/rootReducer/reducer';
import {
  StyledMobile,
  StyledBackgroundMobile,
  StyledPyramidMobile,
  StyledResultMobile,
} from './styles';

export default function MobileQsorting({
  currentQsortingData,
  answers,
}: {
  currentQsortingData: TPersonalPreferences;
  answers: any;
}) {
  const dispatch = useDispatch();
  const { name } = currentQsortingData;
  const boxCounter = 9;

  const handleSelectChange = (item: any, id: number) => {
    dispatch(
      changeAnswers({
        name: `q-${name}`,
        value: {
          destination: id,
          element: item.target.value,
        },
      }),
    );
  };

  return (
    <StyledMobile>
      <StyledBackgroundMobile background={background}>
        <StyledPyramidMobile>
          {Array.from({ length: boxCounter }, (_, x) => (
            <div key={x} className={`box square div${x + 1}`}>
              {answers[x + 1][0] ? (
                <StyledResultMobile>{answers[x + 1][0].name}</StyledResultMobile>
              ) : (
                <Select
                  placeholder="Select option"
                  m={1}
                  size="xs"
                  onChange={(item) => handleSelectChange(item, x + 1)}
                >
                  {answers[0].map((item: any) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              )}
            </div>
          ))}
        </StyledPyramidMobile>
        <Container small>
          <Flex justifyContent="space-between" mt="40px">
            <Flex color="#5431B7" fontSize="13px" fontWeight="600">
              LESS IMPORTANT
            </Flex>
            <Flex color="#5431B7" fontSize="13px" fontWeight="600">
              IMPORTANT
            </Flex>
            <Flex color="#5431B7" fontSize="13px" fontWeight="600">
              VERY IMPORTANT
            </Flex>
          </Flex>
        </Container>
        <SpacebridgeFooter />
      </StyledBackgroundMobile>
    </StyledMobile>
  );
}
