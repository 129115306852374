import styled from '@emotion/styled';

export const StyledTittleWrapper = styled.div<{ gray?: boolean }>`
  margin-bottom: 5px;
  margin-top: 55px;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(props) => (props.gray ? '#A4A4A4' : '#0C0C0C')};

  span {
    color: #5431b7;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    width: 100px;
  }
`;

export const StyledTextWrapper = styled.div<{ gray?: boolean }>`
  color: ${(props) => (props.gray ? '#A4A4A4' : '#0C0C0C')};
  margin-bottom: 25px;
  margin-top: 15px;
  font-weight: 500;
`;

export const StyledButtons = styled.div`
  @media (max-width: 991px) {
    > div > button {
      max-width: 100%;
      justify-content: center;
    }
  }
`;
