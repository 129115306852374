import React from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
// import { TeamFit } from './TeamFit';

export function TeamAnalysisItem({
  label,
  first,
  backgroundColor,
  tooltip,
}: // fit,
{
  label: string;
  first?: boolean;
  backgroundColor?: string;
  tooltip?: string;
  // fit?: number;
}) {
  return (
    <Flex
      justifyContent="space-between"
      padding="12px 24px 12px 0"
      borderTop={first ? 0 : '1px solid #F7F6FA'}
    >
      <Tooltip
        label={tooltip}
        hasArrow
        placement="top"
        backgroundColor="#000"
        borderRadius="0"
        p="12px"
      >
        {first ? (
          <Text
            fontSize="12px"
            fontWeight="600"
            lineHeight="20px"
            p="4px 6px"
            backgroundColor={backgroundColor}
            borderRadius="4px"
          >
            {label}
          </Text>
        ) : (
          <Text fontSize="12px" fontWeight="600" lineHeight="20px" pl="7px">
            {label}
          </Text>
        )}
      </Tooltip>
      <Flex alignItems="center">{/* <TeamFit fit={fit} /> */}</Flex>
    </Flex>
  );
}

TeamAnalysisItem.defaultProps = {
  first: false,
  backgroundColor: '#fff',
  tooltip: '',
  // fit: 0,
};
