import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { Flex, Tooltip } from '@chakra-ui/react';

function Draggable({ elem }: any) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: elem.id,
  });
  const style = transform
    ? { transform: `translate3d(${transform.x}px, ${transform.y}px, 0)` }
    : undefined;

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes} id={`draggable-${elem.id}`}>
      <Flex
        height="116px"
        width="116px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        borderRadius="2px"
        backgroundColor="#8A69EA"
        color="#FFF"
        textAlign="center"
        border="1px solid #5431B7"
        cursor="pointer"
        transition=".3s"
        padding="4px"
        fontWeight="500"
        _hover={{
          backgroundColor: '#5431B7',
          borderColor: '#5431B7',
          color: '#FFF',
        }}
      >
        <div>{elem.name}</div>
        <Tooltip
          hasArrow
          label={elem.tooltip}
          placement="top"
          backgroundColor="#000"
          borderRadius="0"
          p="12px"
        >
          <Flex position="absolute" top="10px" right="10px">
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.30729 10.504H10.6633V10.132C10.6633 8.668 12.7513 8.692 12.7513 6.664C12.7513 5.296 11.7673 4.36 10.0873 4.36C8.40729 4.36 7.32729 5.296 7.32729 7.012V7.18H8.64729V7.012C8.64729 6.232 9.12729 5.536 10.0753 5.536C10.9753 5.536 11.3953 6.196 11.3953 6.808C11.3953 8.188 9.30729 8.128 9.30729 10.144V10.504ZM9.97929 13.12C10.4113 13.12 10.7473 12.796 10.7473 12.34C10.7473 11.884 10.4113 11.572 9.97929 11.572C9.54729 11.572 9.19929 11.884 9.19929 12.34C9.19929 12.796 9.54729 13.12 9.97929 13.12Z"
                fill="#FFF"
              />
            </svg>
          </Flex>
        </Tooltip>
      </Flex>
    </div>
  );
}

export default Draggable;
