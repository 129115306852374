import styled from '@emotion/styled';

export const StyledName = styled.div`
  width: 100%;
  background: #F6F4FC;
  padding: 24px;
  display: flex;
  align-items: flex-end;

  > div {
    width: 100%;
  }

  input {
    width: 100%;
  }
`;
