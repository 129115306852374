import React from 'react';
import { Text as ChakraText } from '@chakra-ui/react';

export function Text({ children }: { children: React.ReactNode }) {
  return (
    <ChakraText fontSize="13px" fontWeight="500" lineHeight="19px" mb="30px" maxWidth="630px">
      {children}
    </ChakraText>
  );
}
