import React, { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'components/Button';

function LogoutButton() {
  const { logout, isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Button onClick={() => logout()} type="button" maxWidth="200px">
      Sign Out
    </Button>
  );
}

export default LogoutButton;
