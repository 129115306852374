import styled from '@emotion/styled';

type ContainerProps = {
  small?: boolean;
  qsort?: boolean;
  nav?: boolean;
  home?: boolean;
  dashboard?: boolean;
  disabled?: boolean;
};

export const StyledContainer = styled.div<ContainerProps>`
  width: 100%;
  margin: 0 auto;

  ${({ small }: ContainerProps) =>
    small &&
    `
      max-width: 590px;
  `}

  ${({ small, qsort }: ContainerProps) =>
    !small &&
    qsort &&
    `
      max-width: 690px;
  `}

  ${({ small, qsort }: ContainerProps) =>
    !small &&
    !qsort &&
    `
      max-width: 1200px;
  `}

  ${({ nav, home }: ContainerProps) =>
    !home &&
    nav &&
    `
      max-width: 1920px;
  `}
  
  ${({ dashboard }: ContainerProps) =>
    dashboard &&
    `
      max-width: 1100px;
  `}
  
  ${({ disabled }: ContainerProps) =>
    disabled &&
    `
      pointer-events: none;
  `}

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;
