import React, { ReactNode } from 'react';
import { StyledContainer } from './styles';

export function Container({
  children,
  small,
  qsort,
  nav,
  dashboard,
  home,
  disabled,
}: {
  children: ReactNode[] | ReactNode;
  small?: boolean;
  qsort?: boolean;
  nav?: boolean;
  dashboard?: boolean;
  home?: boolean;
  disabled?: boolean;
}) {
  return (
    <StyledContainer
      small={small}
      qsort={qsort}
      nav={nav}
      home={home}
      dashboard={dashboard}
      disabled={disabled}
    >
      {children}
    </StyledContainer>
  );
}

Container.defaultProps = {
  small: false,
  qsort: false,
  nav: false,
  home: false,
  dashboard: false,
  disabled: false,
};
