import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as RoutesList from 'common/routes';
import { TStore } from 'types/general.types';

function Items({ currentStep }: { currentStep: number }) {
  const flow = useSelector((state: TStore) => state.survey.survey.is_candidate_flow);
  return (
    <Flex alignItems="center" pointerEvents="none">
      <Link to={RoutesList.TECH}>
        <Text
          fontSize="13px"
          fontWeight="500"
          mr="30px"
          color={currentStep === 1 ? '#000' : '#A4A4A4'}
          position="relative"
          _before={{
            content: '""',
            position: 'absolute',
            width: '9.6px',
            height: '1px',
            top: 0,
            bottom: 0,
            margin: 'auto',
            right: '-20.5px',
            backgroundColor: '#D2D2D2',
            color: '#A4A4A4',
          }}
        >
          Technologies
        </Text>
      </Link>
      {!flow && (
        <Link to={RoutesList.STRENGTHS_SCREEN}>
          <Text
            fontSize="13px"
            fontWeight="500"
            mr="30px"
            position="relative"
            color={currentStep === 2 ? '#000' : '#A4A4A4'}
            _before={{
              content: '""',
              position: 'absolute',
              width: '9.6px',
              height: '1px',
              top: 0,
              bottom: 0,
              margin: 'auto',
              right: '-20.5px',
              backgroundColor: '#D2D2D2',
            }}
          >
            Strengths
          </Text>
        </Link>
      )}
      <Link to={RoutesList.PREFERENCES_SCREEN}>
        <Text fontSize="13px" fontWeight="500" color={currentStep === 3 ? '#000' : '#A4A4A4'}>
          Preferences
        </Text>
      </Link>
    </Flex>
  );
}

export default Items;
