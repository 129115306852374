export const headers: any = {
  NAME_TITLE: 'Hi,',
  NAME_DESCRIPTION: 'Please tell us a bit about you, so we can personalise your experience.',
  TECH_TITLE: 'Please tell us more...',
  TECH_DESCRIPTION: '',
  EXPERIENCE_HEADER: 'How long have you been on your team?',
  STRENGHTS_HEADER_1: 'Climate',
  STRENGHTS_DESCRIPTION_1: 'Choose strengths of your team',
  STRENGHTS_HEADER_2: 'Software Development',
  STRENGHTS_DESCRIPTION_2: 'Choose strengths of your team',
  STRENGHTS_HEADER_3: 'Product Management',
  STRENGHTS_DESCRIPTION_3: 'Choose strengths of your team',
  STRENGHTS_HEADER_4: 'Work Style',
  STRENGHTS_DESCRIPTION_4: 'Choose strengths of your team',
  QSORT_HEADER_1: 'Climate',
  QSORT_DESCRIPTION_1: 'Arrange your preferences from most important (on the right) to least important (on the left)',
  QSORT_HEADER_2: 'Software Development',
  QSORT_DESCRIPTION_2: 'Arrange your preferences from most important (on the right) to least important (on the left)',
  QSORT_HEADER_3: 'Product Management',
  QSORT_DESCRIPTION_3: 'Arrange your preferences from most important (on the right) to least important (on the left)',
  QSORT_HEADER_4: 'Work Style',
  QSORT_DESCRIPTION_4: 'Arrange your preferences from most important (on the right) to least important (on the left)',
};
