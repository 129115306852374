import { createSlice } from '@reduxjs/toolkit';

export const surveySlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    labels: [],
    // company
    company: {},
    companyDetails: [],
    companyMatch: {},
    companySummary: {},
    // team
    teamTopStrengths: {},
    teamTopPreferences: {},
    teamDetails: {},
    teamTableDetails: {},
    teamMatches: {},
    fit: 0,
    teams: {},
    teamEntries: {},
    bestPreferences: {},
    skills: {},
    usersBestTeam: {},
    // company dashboard
    companyDashboardInfo: {},
    companySkills: {},
    companyMatches: {},
    companyTableDetails: {},
  },
  reducers: {
    setLoading: (state: any, action: any) => {
      state.loading = action.payload;
    },
    // company
    updateCompanyInfo: (state: any, action: any) => {
      state.company = action.payload;
    },
    updateCompanyDetails: (state: any, action: any) => {
      state.companyDetails = action.payload;
    },
    updateCompanyMatch: (state: any, action: any) => {
      state.companyMatch = action.payload;
    },
    updateCompanySummary: (state: any, action: any) => {
      state.companySummary = action.payload;
    },
    // team
    updateTeamTopPreferences: (state: any, action: any) => {
      state.teamTopPreferences = action.payload.value;
    },
    updateTeamTopStrengths: (state: any, action: any) => {
      state.teamTopStrengths = action.payload.value;
    },
    updateTeamDetails: (state: any, action: any) => {
      state.teamDetails = action.payload.value;
    },
    updateDashboardTeamTableDetails: (state: any, action: any) => {
      state.teamTableDetails = action.payload.value;
    },
    updateTeamMatches: (state: any, action: any) => {
      state.teamMatches[action.payload.name] = action.payload.value;
    },
    updateTeamEntries: (state: any, action: any) => {
      state.teamEntries[action.payload.name] = action.payload.value;
    },
    updateUsersBestTeam: (state: any, action: any) => {
      state.usersBestTeam = action.payload.value;
    },
    updateDashboardTeamInfo: (state: any, action: any) => {
      state[action.payload.name] = action.payload.value;
    },
    updateLabels: (state: any, action: any) => {
      state.labels = action.payload.value;
    },
    // company
    updateDashboardCompanyDetails: (state: any, action: any) => {
      state.companyDashboardInfo = action.payload.value;
    },
    updateCompanySkills: (state: any, action: any) => {
      state.companySkills = action.payload.value;
    },
    updateCompanyMatches: (state: any, action: any) => {
      state.companyMatches[action.payload.name] = action.payload.value;
    },
    updateDashboardCompanyTableDetails: (state: any, action: any) => {
      state.companyTableDetails = action.payload.value;
    },
  },
});

export const {
  updateCompanyInfo,
  updateCompanyDetails,
  updateCompanyMatch,
  updateCompanySummary,
  updateTeamTopPreferences,
  updateTeamTopStrengths,
  updateTeamDetails,
  updateDashboardTeamTableDetails,
  updateDashboardTeamInfo,
  updateTeamMatches,
  updateTeamEntries,
  setLoading,
  updateUsersBestTeam,
  updateDashboardCompanyDetails,
  updateLabels,
  updateCompanySkills,
  updateCompanyMatches,
  updateDashboardCompanyTableDetails,
} = surveySlice.actions;

export default surveySlice.reducer;
