import styled from '@emotion/styled';

type NavbarProps = {
  home?: boolean;
  top?: boolean;
  black?: boolean;
  custom?: boolean;
};

export const StyledNavbar = styled.div<NavbarProps>`
  ${(props: NavbarProps) =>
    props.top &&
    `
      height: 67px;
      border-bottom: 1px solid #EEEEEE;
      display: flex;
      align-items: center;
      justify-content: space-between;
  `}

  ${(props: NavbarProps) =>
    props.home &&
    `
      height: 30px;
      padding-top: 38px;
  `}

  ${(props: NavbarProps) =>
    props.black &&
    `
      background-color: #000;
      height: 56px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #282828;

      .logo-link {
        display: flex;
        align-items: center;
      }
  `}

  ${(props: NavbarProps) =>
    props.custom &&
    `
    img {
      height: 25px;
      max-height: 35px;
    }
  `}
`;
