import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import surveyReducer from './rootReducer/reducer';
import dashboardReducer from './dashboardReducer/reducer';

const reducers = combineReducers({
  survey: surveyReducer,
  dashboard: dashboardReducer,
});

const persistConfig = {
  key: 'root',
  storage: storageSession,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
});
