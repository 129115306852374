import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Strengths, Navbar, Container, FixedFooter, DevRouting } from 'components';
import SpacebridgeFooter from 'components/SpacebridgeFooter';
import * as RoutesList from 'common/routes';
import { TStore } from 'types/general.types';
import { headers } from 'common/headers';
import { StyledBadge, StyledDescription } from './styles';

export function TeamEvaluation({ order }: { order: number }) {
  const evaluationData = useSelector((state: TStore) => state.survey.survey.team_strengths);
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);
  const disabledCategories = useSelector((state: TStore) => state.survey.disabledCategories);
  const currentEvalutaionData = evaluationData[order - 1];
  const isDisabled = disabledCategories.includes(`strengths-${order}`);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getNextRoute = () => {
    if (order === 1) {
      return RoutesList.TEAM2;
    }
    if (order === 2) {
      return RoutesList.TEAM3;
    }
    if (order === 3) {
      return RoutesList.TEAM4;
    }
    if (order === 4) {
      return RoutesList.PREFERENCES_SCREEN;
    }
    return '';
  };

  const getBackRoute = () => {
    if (order === 1) {
      return RoutesList.STRENGTHS_SCREEN;
    }
    if (order === 2) {
      return RoutesList.TEAM1;
    }
    if (order === 3) {
      return RoutesList.TEAM2;
    }
    if (order === 4) {
      return RoutesList.TEAM3;
    }
    return '';
  };

  const nextRoute = getNextRoute();
  const backRoute = getBackRoute();

  return (
    <div>
      <Navbar
        top
        nav
        currentStep={2}
        navbarImage={companyData?.logo_url}
        progressValue={order * 10 + 20}
      />
      <Container small disabled={isDisabled}>
        <StyledBadge order={order}>{headers[`STRENGHTS_HEADER_${order}`]}</StyledBadge>
        <StyledDescription>
          <p>{headers[`STRENGHTS_DESCRIPTION_${order}`]}</p>
        </StyledDescription>
        <Strengths currentEvalutaionData={currentEvalutaionData} gray={isDisabled} />
        <SpacebridgeFooter />
      </Container>
      <FixedFooter
        name="strengths"
        order={order}
        isDisabled={isDisabled}
        back={backRoute}
        next={nextRoute}
        canBeDisabled={order === 2 || order === 3}
      />
      <DevRouting />
    </div>
  );
}
