import styled from '@emotion/styled';

export const StyledCard = styled.div<{ empty?: boolean; popup?: boolean }>`
  background: #fff;
  border: 1px solid #f0edf9;
  border-radius: 4px;
  width: 49%;
  padding: 0 17px 11px 17px;
  ${({ empty }) =>
    empty &&
    `
    min-height: 240px;
    padding: 0;
  `}
  ${({ popup }) =>
    popup &&
    `
    width: 97%;
  `}
`;

export const StyledModal = styled.div`
  > span {
    padding: 0 17px 11px 17px;
  }
`;
