import React, { ReactNode } from 'react';
import { Button as ChakraButton } from '@chakra-ui/react';
import arrow from 'assets/icons/arrow.svg';

export function Button({
  children,
  secondary,
  iconBefore,
  iconAfter,
  onClick,
  ...props
}: {
  children: ReactNode;
  secondary?: boolean;
  iconBefore?: boolean;
  iconAfter?: boolean;
  onClick?: () => void;
  [x: string]: unknown;
}) {
  if (secondary) {
    return (
      <ChakraButton
        size="sm"
        padding="19.5px 0"
        color="#0B2219"
        fontWeight="600"
        fontSize="14px"
        textTransform="uppercase"
        borderRadius={0}
        backgroundColor="transparent"
        transition="0.3s"
        mt="15px"
        maxWidth="fit-content"
        width="100%"
        height="56px"
        onClick={onClick}
        display="flex"
        justifyContent={!iconBefore ? 'center' : 'flex-start'}
        alignItems="center"
        _focus={{ boxShadow: 'none' }}
        _hover={{ backgroundColor: 'transparent' }}
        _active={{ backgroundColor: 'transparent' }}
        {...props}
      >
        {iconBefore && <img style={{ marginRight: 8 }} src={arrow} alt="" />}
        {children}
        {iconAfter && (
          <img style={{ marginLeft: 8, transform: 'rotate(-180deg)' }} src={arrow} alt="" />
        )}
      </ChakraButton>
    );
  }
  return (
    <ChakraButton
      size="sm"
      padding="19.5px 80.5px"
      color="#0B2219"
      fontWeight="600"
      fontSize="14px"
      textTransform="uppercase"
      borderRadius={0}
      backgroundColor="#5AE2A1"
      transition="0.3s"
      mt="15px"
      width="100%"
      height="56px"
      _focus={{ boxShadow: 'none' }}
      _hover={{ backgroundColor: '#52DA99', border: '1px solid #49AA7C' }}
      _active={{ backgroundColor: '#52DA99', border: '1px solid #49AA7C' }}
      onClick={onClick}
      {...props}
    >
      {iconBefore && <img style={{ marginRight: 8 }} src={arrow} alt="" />}
      {children}
      {iconAfter && (
        <img style={{ marginLeft: 8, transform: 'rotate(-180deg)' }} src={arrow} alt="" />
      )}
    </ChakraButton>
  );
}

Button.defaultProps = {
  onClick: null,
  secondary: false,
  iconBefore: false,
  iconAfter: false,
};
