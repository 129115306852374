import React, { useState } from 'react';
import { Flex, Box, useToast, Text } from '@chakra-ui/react';
import { Navbar, Container, DevRouting, FixedFooter, Input, Button } from 'components';
import * as RoutesList from 'common/routes';
import { useSelector } from 'react-redux';
import { StyledTextWrapper, StyledTittleWrapper } from 'components/Experience/styles';
import { headers } from 'common/headers';
import { TStore } from 'types/general.types';
import http from 'services/http/HttpService';
import { StyledName } from './styles';

export default function NameScreen() {
  const [userName, setUserName] = useState<string>('');
  const [isImputVisible, setInputVisible] = useState(true);
  const toast = useToast();
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);
  const surveyId = useSelector((state: TStore) => state.survey.survey.id);

  const handeSendButtonClick = () => {
    http.post(`/surveys/${surveyId}/name`, { full_name: userName }).then((data) => {
      if (data.status === 200) {
        setInputVisible(false);
        toast({
          isClosable: true,
          duration: 5000,
          containerStyle: { width: 500, maxWidth: 500 },
          render: () => (
            <Box
              fontSize="14px"
              color="#000"
              p={3}
              bg="#CAF9E2"
              borderRadius="77px"
              textAlign="center"
              mb="15px"
              fontWeight="600"
            >
              Thank you. Your name and surname has been saved.
            </Box>
          ),
        });
      }
    });
  };

  return (
    <div>
      <Navbar top nav currentStep={1} navbarImage={companyData?.logo_url} progressValue={10} />
      <Container small>
        <StyledTittleWrapper>
          <h1>{headers.NAME_TITLE}</h1>
        </StyledTittleWrapper>
        <StyledTextWrapper>
          <p>{headers.NAME_DESCRIPTION}</p>
        </StyledTextWrapper>
        <Flex justifyContent="center" alignItems="flex-start" w="full" direction="column">
          <StyledName>
            {isImputVisible ? (
              <>
                <Input
                  label="Name and surname"
                  placeholder="Please enter your name and surname"
                  backgroundColor="#fff"
                  onChange={(e: any) => setUserName(e.target.value)}
                  data-testid="input-name-and-surname"
                />
                <Button
                  maxWidth={{ base: 'fit-content', md: '210px' }}
                  padding={{ base: '20px', md: 'inherit' }}
                  onClick={handeSendButtonClick}
                  data-testid="button-save"
                >
                  SAVE
                </Button>
              </>
            ) : (
              <Text>Your name and surname has been saved. Thank you!</Text>
            )}
          </StyledName>
          <Text mt="10px">*This step is optional.</Text>
        </Flex>
      </Container>
      <FixedFooter
        name="name"
        order={1}
        back={RoutesList.INFORMATIONS}
        next={RoutesList.TECH}
        canBeDisabled={false}
        isDisabled={false}
        isButtonDisabled={false}
      />
      <DevRouting />
    </div>
  );
}
