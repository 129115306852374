import React from 'react';
import { Text } from '@chakra-ui/react';

export function Heading({ children }: { children: React.ReactNode }) {
  return (
    <Text mt="80px" mb="24px" fontWeight="600" fontSize="24px" maxWidth="630px">
      {children}
    </Text>
  );
}
