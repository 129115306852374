import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal, ModalOverlay, ModalContent, ModalBody, Text } from '@chakra-ui/react';
import { Button, Navbar } from 'components';
import * as RoutesList from 'common/routes';
import { TStore } from 'types/general.types';

function QuestionAnswer({ question, answer }: { question: string; answer: string | any[] }) {
  return (
    <>
      <hr />
      <Text fontSize="18px" fontWeight="600" my="16px">
        {question}
      </Text>
      <Text fontSize="14px" fontWeight="500" mb="16px">
        {answer}
      </Text>
    </>
  );
}

export function Informations() {
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);
  const history = useNavigate();
  const onClose = () => {
    history(RoutesList.NAME);
  };
  const answerTwoLine1 =
    "It's safe with us, promise. Your answers will be kept confidential. And, hey, they're yours, so we do whatever you want us to do with them.";
  const answerTwoLine2 =
    "When you're done, you'll be able to opt-in to receive your answers by email.";

  return (
    <div>
      <Navbar top nav navbarImage={companyData?.logo_url} />
      <Modal isOpen isCentered id="modal" onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Text backgroundColor="#F0EDF9" py="10px" mb="50px" textAlign="center">
              This survey will take you about <span>12 minutes</span> to complete
            </Text>
            <Text fontWeight="600" mb="16px">
              Thanks so much for being here. It&apos;s people like you who keep our bold vision
              alive: the world where everyone feels like they belong at work.
            </Text>
            <QuestionAnswer
              question="Why we're doing this"
              answer="To get a better understanding of what person-team fit is about
              - and how to strive for it."
            />
            <QuestionAnswer
              question="What about your data"
              answer={[answerTwoLine1, <br />, <br />, answerTwoLine2]}
            />
            <Button onClick={onClose} data-testid="modal-button">
              Let&apos;s go
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
}
