import styled from '@emotion/styled';

type StrengthTagProps = {
  background?: string;
  space?: boolean;
  small?: boolean;
  border?: string;
  borderRadius?: string;
};

export const StyledStrengthTag = styled.div<StrengthTagProps>`
  height: ${({ small }) => (small ? '27px' : '40px')};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ borderRadius }) => borderRadius || '2px'};
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  color: #000;
  padding: 14px 8px;
  text-align: center;
  overflow: hidden;
  ${({ background }: StrengthTagProps) =>
    `
    background: ${background};
  `}
  ${({ border }: StrengthTagProps) =>
    border &&
    `
    border: ${border};
  `}
  ${({ space }: StrengthTagProps) =>
    space &&
    `
    margin-right: 5px;
  `}
`;
