import React, { ReactNode } from 'react';
import { Tag as ChakraTag } from '@chakra-ui/react';

export function Tag({ children }: { children: ReactNode }) {
  return (
    <ChakraTag
      backgroundColor="#EAE3FE"
      color="#000"
      p="10px 16px"
      fontWeight="400"
      fontSize="13px"
      borderRadius="66px"
      marginTop="auto"
    >
      {children}
    </ChakraTag>
  );
}
