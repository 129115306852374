import styled from '@emotion/styled';

export const StyledAutocomplete = styled.div`
  width: 100%;
  background: #F6F4FC;
  padding: 24px;

  .react-select__control {
    border: 1px solid #d4c7f8;
    background: #fff;
    height: 80px;
    padding: 10px;
    border-radius: 0;
    box-shadow: none;

    &:focus,
    &[data-focus] {
      border-color: #d4c7f8;
      box-shadow: none;
    }
  }

  .react-select__multi-value {
    border: 1px solid #5431b7;
    background: #8a69ea;
    color: #fff;
    height: 34px;
    border-radius: 2px;
  }

  .react-select__value-container {
    align-items: flex-start;
    align-content: flex-start;
    padding: 0;
  }

  .react-select__input-container {
    height: 36px;
  }

  .react-select__input {
    min-width: 100px;
  }

  .react-select__placeholder {
    top: 17px;
  }
`;
