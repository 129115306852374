import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as RoutesList from 'common/routes';
import { Container } from 'components';
import { Flex, Text } from '@chakra-ui/react';
import addGray from 'assets/icons/add-gray.svg';
import { TStore } from 'types/general.types';
import { StrengthTag } from '../../StrenghtTag';
import { TeamFit } from './TeamFit';
import { AnimatedCard } from '../../AnimatedCard';

export function TeamHeader({ noBack }: { noBack?: boolean }) {
  const history = useNavigate();
  const teamDetails = useSelector((state: TStore) => state.dashboard.teamDetails);
  const fit = useSelector((state: TStore) => state.dashboard.fit);
  const skills = useSelector((state: TStore) => state.dashboard.skills);
  const teamName = teamDetails?.team_name;
  const companyId = teamDetails?.company_id;
  const entriesCount = teamDetails?.team_size;
  const fullfilledEntries = teamDetails?.fulfilled_entries;

  const handleBackButtonClick = () => {
    history(`${RoutesList.DASHBOARD}/${companyId}`);
  };

  return (
    <Flex
      flexDirection="column"
      background="#000"
      color="#fff"
      padding="18px 0 38px 0"
      width="100%"
      margin="0 auto"
    >
      <Container dashboard>
        {!noBack ? (
          <Flex
            alignItems="center"
            mb="16px"
            width="fit-content"
            cursor="pointer"
            onClick={handleBackButtonClick}
          >
            <img src={addGray} alt="add" />
            <Text
              color="#5E5E5E"
              fontSize="14px"
              fontWeight="600"
              ml="8px"
              textTransform="uppercase"
            >
              Back
            </Text>
          </Flex>
        ) : (
          <Flex mt="15px" />
        )}
        <Flex>
          <Text fontSize="32px" fontWeight="600" mb="24px" lineHeight="1">
            {teamName}
          </Text>
        </Flex>
        <Flex justifyContent="space-evenly" h="123px">
          <AnimatedCard
            border="solid 1px #282828"
            borderRadius="4px"
            p="24px"
            flexDirection="column"
            mr="24px"
          >
            <Text fontSize="16px" fontWeight="500" mb="16px">
              Status
            </Text>
            <Flex>
              <Flex
                mt="8px"
                mr="8px"
                w="8px"
                h="8px"
                borderRadius="50%"
                border={`solid 5px ${entriesCount === fullfilledEntries ? '#5AE2A1' : '#C1C1C1'}`}
              />
              <Text fontSize="18px" fontWeight="600">
                {entriesCount === fullfilledEntries ? 'Ready' : 'In progress'}
              </Text>
            </Flex>
          </AnimatedCard>
          <AnimatedCard
            border="solid 1px #282828"
            borderRadius="4px"
            p="24px"
            flexDirection="column"
            mr="24px"
          >
            <Text fontSize="16px" fontWeight="500" mb="16px">
              Team Fit
            </Text>
            <TeamFit fit={fit} big team />
          </AnimatedCard>
          <AnimatedCard
            border="solid 1px #282828"
            borderRadius="4px"
            p="24px"
            flexDirection="column"
            mr="24px"
          >
            <Text fontSize="16px" fontWeight="500" mb="16px">
              Employees
            </Text>
            <Flex>
              <Text fontSize="24px" fontWeight="600">
                {fullfilledEntries}
              </Text>
              <Text color="#5E5E5E" fontSize="24px" fontWeight="600">
                /{entriesCount}
              </Text>
            </Flex>
          </AnimatedCard>
          <AnimatedCard
            border="solid 1px #282828"
            borderRadius="4px"
            p="24px"
            flexDirection="column"
            flex={1}
            mr="24px"
          >
            <Text fontSize="16px" fontWeight="500" mb="16px" mr="15px">
              Technologies Used
            </Text>
            <Flex>
              {skills?.top_five?.slice(0, 4).map((skill: string, index: number) => (
                <StrengthTag key={index} background="#F6F4FC" space>
                  <Text>{skill}</Text>
                </StrengthTag>
              ))}
            </Flex>
          </AnimatedCard>
          <AnimatedCard
            border="solid 1px #282828"
            borderRadius="4px"
            p="20px"
            flexDirection="column"
          >
            <Text fontSize="16px" fontWeight="500" mb="16px">
              Job Slots
            </Text>
            <Flex>
              <Text color="#5E5E5E" fontSize="24px" fontWeight="600">
                0/0
              </Text>
            </Flex>
          </AnimatedCard>
        </Flex>
      </Container>
    </Flex>
  );
}

TeamHeader.defaultProps = {
  noBack: false,
};
