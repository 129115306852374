import styled from '@emotion/styled';

export const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 68px);
  overflow: hidden;

  canvas {
    margin-top: 67px;
    width: 100%;
    height: 100%;
  }
`;

export const StyledEmailWrapper = styled.div`
  .chakra-button {
    padding: 18px 24px;
    margin-left: 18px;
  }
`;
