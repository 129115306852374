import styled from '@emotion/styled';

type StyledTeamFitProps = {
  big?: boolean;
};

export const StyledTeamFit = styled.div<StyledTeamFitProps>`
  margin-left: 5px;
  .line-icon {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .line {
    width: ${({ big }: StyledTeamFitProps) => (big ? '7px' : '3px')};
    border-radius: 1.8px;
  }
  .line-1 {
    height: ${({ big }: StyledTeamFitProps) => (big ? '13px' : '6px')};
    margin-right: ${({ big }: StyledTeamFitProps) => (big ? '5px' : '3px')};
  }
  .line-2 {
    height: ${({ big }: StyledTeamFitProps) => (big ? '19px' : '9px')};
    margin-right: ${({ big }: StyledTeamFitProps) => (big ? '5px' : '3px')};
  }
  .line-3 {
    height: ${({ big }: StyledTeamFitProps) => (big ? '27px' : '12px')};
    margin-right: ${({ big }: StyledTeamFitProps) => (big ? '5px' : '3px')};
  }
  .line-4 {
    height: ${({ big }: StyledTeamFitProps) => (big ? '34px' : '15px')};
  }
  .line-icon-4 .line-1,
  .line-icon-4 .line-2,
  .line-icon-4 .line-3,
  .line-icon-4 .line-4 {
    background-color: #5431b7;
  }
  .line-icon-3 .line-1,
  .line-icon-3 .line-2,
  .line-icon-3 .line-3 {
    background-color: #5431b7;
  }
  .line-icon-3 .line-4 {
    background-color: #d4c7f8;
  }
  .line-icon-2 .line-1,
  .line-icon-2 .line-2 {
    background-color: #5431b7;
  }
  .line-icon-2 .line-3,
  .line-icon-2 .line-4 {
    background-color: #d4c7f8;
  }
  .line-icon-1 .line-1 {
    background-color: #5431b7;
  }
  .line-icon-1 .line-2,
  .line-icon-1 .line-3,
  .line-icon-1 .line-4 {
    background-color: #d4c7f8;
  }
  .line-icon-0 .line-1,
  .line-icon-0 .line-2,
  .line-icon-0 .line-3,
  .line-icon-0 .line-4 {
    background-color: #d4c7f8;
  }
`;

export const StyledDashboardTeamPageWrapper = styled.div`
  hr {
    margin: 24px 0;
    border-color: #d4c7f8;
  }
`;

export const StyledAvatars = styled.div<{
  black?: boolean;
  right?: boolean;
  open?: boolean;
  opacity?: boolean;
}>`
  > span {
    margin-right: -28px;
    border: 1px solid #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 600;
    font-size: 16px;
  }

  ${({ open }: { open?: boolean }) =>
    open &&
    `
    > span {
      margin-right: 7px;
    }
  `}

  ${({ black }: { black?: boolean }) =>
    black &&
    `
    > span {
      margin-right: -6px;
      border: 1px solid #D4C7F8;
      > div {
        text-transform: capitalize;
      }
    }
  `}
  
  ${({ black }: { black?: boolean }) =>
    !black &&
    `
    > span {  
      &:last-child {
        box-shadow: 0px 3.94844px 6px rgba(0, 0, 0, 0.12);
        border: 0;
      }
    }
  `}
  
  ${({ right }: { right?: boolean }) =>
    right &&
    `
    margin-right: 20px;
  `}
  
  ${({ opacity }: { opacity?: boolean }) =>
    opacity &&
    `
    > span {
      opacity: 0.5;
      &:first-child {
        opacity: 1;
      }
    }
  `}
`;

export const StyledTeamChanges = styled.div`
  span {
    font-weight: 800;
    color: black;
  }
`;
