/* eslint-disable indent */
const calculateQsortBigColumnIndex = (index: number) => {
  switch (true) {
    case index === 0:
      return 0;
    case index < 3:
      return 1;
    case index >= 3 && index < 6:
      return 2;
    case index >= 6 && index < 10:
      return 3;
    case index >= 10 && index < 13:
      return 4;
    case index >= 13:
      return 5;
    default:
      return 0;
  }
};

const calculateQsortColumnIndex = (index: number) => {
  switch (true) {
    case index === 0:
      return 0;
    case index < 2:
      return 1;
    case index >= 2 && index < 4:
      return 2;
    case index >= 4 && index < 7:
      return 3;
    case index >= 7 && index < 9:
      return 4;
    case index >= 9:
      return 5;
    default:
      return 0;
  }
};

export const handleAnswersStructure = (
  surveryId: string,
  state: any,
  flow: boolean,
  disabledCategories: string[],
) => ({
  survey_id: surveryId,
  experience: null,
  skills: state.skills.value,
  want_to_learn_skills: state.learnSkills.value,
  team_strengths_responses: !flow
    ? [
        {
          group_of_strengths_id: 'ffe43c1a-2eb0-4c81-9176-04581276b9f7',
          strengths: !disabledCategories.includes('strengths-1')
            ? state.Climate.value.map((item: { id: string; chosenAt: string }) => ({
                id: item.id,
                chosen_at: item.chosenAt,
              }))
            : null,
        },
        {
          group_of_strengths_id: 'e7a775c3-0876-4962-8771-4f0f47733090',
          strengths: !disabledCategories.includes('strengths-2')
            ? state['Software Development'].value.map((item: { id: string; chosenAt: string }) => ({
                id: item.id,
                chosen_at: item.chosenAt,
              }))
            : null,
        },
        {
          group_of_strengths_id: '83a552f1-9786-454d-8466-2a1c5376940e',
          strengths: !disabledCategories.includes('strengths-3')
            ? state['Product Management'].value.map((item: { id: string; chosenAt: string }) => ({
                id: item.id,
                chosen_at: item.chosenAt,
              }))
            : null,
        },
        {
          group_of_strengths_id: 'b2b44a18-c5a7-4906-88cb-3e14956fdfae',
          strengths: !disabledCategories.includes('strengths-4')
            ? state['Work style'].value.map((item: { id: string; chosenAt: string }) => ({
                id: item.id,
                chosen_at: item.chosenAt,
              }))
            : null,
        },
      ]
    : [],
  personal_preferences_responses: [
    {
      group_of_preferences_id: '6d0c9430-d9b0-4061-a6c5-54ab7a015a9a',
      preferences: !disabledCategories.includes('preferences-1')
        ? state['q-Climate'].value.map((items: any, index: any) => ({
            column_index: calculateQsortBigColumnIndex(index),
            personal_preferences: items.map((item: any) => ({
              id: item.id,
              chosen_at: item.chosen_at,
              element_index: index,
            })),
          }))
        : null,
    },
    {
      group_of_preferences_id: '41c2b60a-471a-454d-b6db-a89ed45a5080',
      preferences: !disabledCategories.includes('preferences-2')
        ? state['q-Software Development'].value.map((items: any, index: any) => ({
            column_index: calculateQsortColumnIndex(index),
            personal_preferences: items.map((item: any) => ({
              id: item.id,
              chosen_at: item.chosen_at,
              element_index: index,
            })),
          }))
        : null,
    },
    {
      group_of_preferences_id: '4a4d19bc-9e8e-4862-b964-a4c1e3af889c',
      preferences: !disabledCategories.includes('preferences-3')
        ? state['q-Product Management'].value.map((items: any, index: any) => ({
            column_index: calculateQsortColumnIndex(index),
            personal_preferences: items.map((item: any) => ({
              id: item.id,
              chosen_at: item.chosen_at,
              element_index: index,
            })),
          }))
        : null,
    },
    {
      group_of_preferences_id: '5a8afd5a-bcc3-4e45-af16-64f52735a9d1',
      preferences: !disabledCategories.includes('preferences-4')
        ? state['q-Work style'].value.map((items: any, index: any) => ({
            column_index: calculateQsortColumnIndex(index),
            personal_preferences: items.map((item: any) => ({
              id: item.id,
              chosen_at: item.chosen_at,
              element_index: index,
            })),
          }))
        : null,
    },
  ],
});
