import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Text, Flex, Box, useToast } from '@chakra-ui/react';
import { Navbar, Tag, Container, Input, Button } from 'components';
import http from 'services/http/HttpService';
import background from 'assets/backgrounds/dots1.svg';
import { updateSurvey, updateUserId } from 'store/rootReducer/reducer';
import * as RoutesList from 'common/routes';
import homeImage from 'assets/icons/home-illustration.svg';
import { StyledHome, StyledHomeLeftWrapper, StyledHomeRightWrapper } from './styles';

function Home() {
  const toast = useToast();
  const dispatch = useDispatch();
  const history = useNavigate();
  const { id } = useParams();
  const [inputText, setInputText] = useState(id);
  const handleSendForm = () => {
    const request = http.get(`/surveys/entries/${inputText}`);
    request
      .then((data) => {
        if (data.status === 200) {
          dispatch(updateUserId(inputText));
          dispatch(updateSurvey(data.data));
          history(RoutesList.INFORMATIONS);
        }
      })
      .catch(() => {
        toast({
          duration: 5000,
          render: () => (
            <Box
              fontSize="14px"
              color="#fff"
              p={3}
              bg="#eb372e"
              borderRadius="77px"
              textAlign="center"
              mb="15px"
            >
              Someone has already used that code.
            </Box>
          ),
        });
      });
  };

  return (
    <StyledHome backgroundImage={background}>
      <Navbar home>
        <Text fontWeight="700" color="#5431B7" cursor="pointer">
          <Link to={RoutesList.HOME} />
        </Text>
      </Navbar>
      <Container>
        <Flex
          justifyContent="space-between"
          minHeight="calc(100vh - 38px)"
          p={{ base: '15px', lg: 0 }}
          px={{ base: '15px', lg: '15px' }}
        >
          <StyledHomeLeftWrapper>
            <Tag>You&apos;ve found a place we think you&apos;ll like</Tag>
            <Text
              color="#5431B7"
              fontSize={{ base: '42px', lg: '62px' }}
              fontWeight="bold"
              marginTop="15px"
              marginBottom="40px"
              lineHeight="120%"
            >
              Hi, we&apos;re Spacebridge
            </Text>
            <Input
              label="This is where you can run our beta. It's invite-only for now."
              placeholder="Enter the invite code"
              background="#fff"
              defaultValue={id || ''}
              _focus={{ borderColor: '#8A69EA' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputText(e.target.value)}
              data-testid="login-input"
            />
            <Button maxWidth="210px" onClick={() => handleSendForm()} data-testid="login-button">
              Sign in
            </Button>
            <Flex paddingBottom="20px" marginTop="auto">
              <Text marginRight="24px" fontSize="13px">
                <Link to="privacy-policy">Privacy Policy</Link>
              </Text>
              <Text marginRight="24px" fontSize="13px">
                <Link to="terms-of-use">Terms of use</Link>
              </Text>
              <Text fontSize="13px">
                <Link to="cookies-policy">Cookies Policy</Link>
              </Text>
            </Flex>
          </StyledHomeLeftWrapper>
          <StyledHomeRightWrapper>
            <img src={homeImage} alt="" />
          </StyledHomeRightWrapper>
        </Flex>
      </Container>
    </StyledHome>
  );
}

export default Home;
