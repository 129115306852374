/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  Text,
} from '@chakra-ui/react';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Button, Navbar, Container, FixedFooter, DevRouting } from 'components';
import background from 'assets/backgrounds/dots.svg';
import arrowLeft from 'assets/icons/arrow2.svg';
import * as RoutesList from 'common/routes';
import { changeAnswers, updateAnswers } from 'store/rootReducer/reducer';
import { TStore } from 'types/general.types';
import { headers } from 'common/headers';
import useWindowDimensions from 'common/hooks';
import http from 'services/http/HttpService';
import { StyledBadge, StyledDescription } from 'pages/team-evaluation/styles';
import SpacebridgeFooter from 'components/SpacebridgeFooter';
import {
  StyledArrowLeft,
  StyledBackground,
  StyledBottom,
  StyledModal,
  StyledPyramid,
} from './styles';
import MobileQsorting from './mobile';
import Droppable from './Droppable';
import { handleAnswersStructure } from './helpers';

export function Qsorting({ order }: { order: number }) {
  const size = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);
  const surveyId = useSelector((state: TStore) => state.survey.survey.id);
  const answers = useSelector((state: TStore) => state.survey.answers);
  const flow = useSelector((state: TStore) => state.survey.survey.is_candidate_flow);
  const qsortingData = useSelector((state: TStore) => state.survey.survey.personal_preferences);
  const disabledCategories = useSelector((state: TStore) => state.survey.disabledCategories);
  const currentQsortingData = qsortingData[order - 1];
  const { name } = currentQsortingData;
  const datedStrengths = currentQsortingData.strengths.map((item: any) => ({
    chosen_at: new Date().toISOString(),
    ...item,
  }));
  const isDisabled = disabledCategories.includes(`preferences-${order}`);
  const isFilled = answers[`q-${name}`]?.value[0]?.length === 0;

  const getNextRoute = () => {
    if (order === 1) {
      return RoutesList.QSORT2;
    }
    if (order === 2) {
      return RoutesList.QSORT3;
    }
    if (order === 3) {
      return RoutesList.QSORT4;
    }
    return '';
  };

  const getBackRoute = () => {
    if (order === 1) {
      return RoutesList.TEAM4;
    }
    if (order === 2) {
      return RoutesList.QSORT1;
    }
    if (order === 3) {
      return RoutesList.QSORT2;
    }
    if (order === 4) {
      return RoutesList.QSORT3;
    }
    return '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const answersLabel = answers[`q-${name}`]?.value;
    const newState = [datedStrengths, [], [], [], [], [], [], [], [], []];
    if (answersLabel.length) {
      dispatch(
        updateAnswers({
          name: `q-${name}`,
          value: answersLabel,
        }),
      );
    } else {
      dispatch(
        updateAnswers({
          name: `q-${name}`,
          value: newState,
        }),
      );
    }
  }, [order]);

  const handleDragEnd = (result: any) => {
    const destination = result.over.id;
    const element = result.active.id;

    dispatch(
      changeAnswers({
        name: `q-${name}`,
        value: {
          destination,
          element,
        },
      }),
    );
  };

  const handleModalClick = () => {
    const structuredAnswers = handleAnswersStructure(
      surveyId,
      answers,
      Boolean(flow),
      disabledCategories,
    );
    http.post('/surveys', structuredAnswers).then((data) => {
      if (data.status === 200) {
        history(RoutesList.THANKS);
      }
    });
  };

  const handeModalClose = () => {
    setIsModalOpen(false);
  };

  const sensors = useSensors(useSensor(MouseSensor));

  const nextRoute = getNextRoute();
  const backRoute = getBackRoute();

  return (
    <Flex flexDirection="column">
      <Navbar
        top
        nav
        currentStep={3}
        navbarImage={companyData?.logo_url}
        progressValue={order === 4 ? 100 : (order + 4) * 10 + 20}
      />
      {size.width < 1050 ? (
        <MobileQsorting
          currentQsortingData={currentQsortingData}
          answers={answers[`q-${name}`]?.value}
        />
      ) : (
        <Flex
          flexDirection="column"
          height="calc(100vh - 67px)"
          pl="330px"
          pointerEvents={isDisabled ? 'none' : 'auto'}
        >
          <Container small>
            <StyledBadge order={order}>{headers[`QSORT_HEADER_${order}`]}</StyledBadge>
            <StyledDescription>
              <p>{headers[`QSORT_DESCRIPTION_${order}`]}</p>
            </StyledDescription>
          </Container>
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <StyledBackground background={background} />
            <StyledPyramid gray={isDisabled}>
              <StyledArrowLeft>
                <img
                  src={arrowLeft}
                  alt=""
                  style={{ filter: isDisabled ? 'grayscale(1)' : undefined }}
                />
              </StyledArrowLeft>
              {answers[`q-${name}`]?.value.map((element: any, index: number) => (
                <div className={`element-${index}`} key={index}>
                  <Droppable
                    element={element}
                    index={index}
                    lastColumn={index > answers[`q-${name}`].value.length - 2}
                    gray={isDisabled}
                  />
                </div>
              ))}
            </StyledPyramid>
          </DndContext>
          <StyledBottom>
            <Container qsort>
              <Flex justifyContent="space-between" mt="40px">
                <Flex color={isDisabled ? '#D2D2D2' : '#5431B7'} fontSize="13px" fontWeight="600">
                  LESS IMPORTANT
                </Flex>
                <Flex color={isDisabled ? '#D2D2D2' : '#5431B7'} fontSize="13px" fontWeight="600">
                  IMPORTANT
                </Flex>
                <Flex color={isDisabled ? '#D2D2D2' : '#5431B7'} fontSize="13px" fontWeight="600">
                  VERY IMPORTANT
                </Flex>
              </Flex>
            </Container>
          </StyledBottom>
          <SpacebridgeFooter />
        </Flex>
      )}
      <StyledModal>
        <Modal isCentered isOpen={isModalOpen} onClose={handeModalClose}>
          <ModalOverlay />
          <ModalContent h="314px" maxWidth="600px" borderRadius={0}>
            <ModalBody p="42px 42px 0 42px">
              <Text fontSize="24px" textAlign="center" fontWeight="600" mb="22px" lineHeight={1}>
                All done?
              </Text>
              <Text fontSize="16px" fontWeight="600" mb="8px">
                Please confirm your survery is complete.
              </Text>
              <Text fontSize="14px" mb="24px">
                Please make sure you have completed all sections of the survey before you send the
                results. Beyond this point, survey will be finished.
              </Text>
              <hr />
            </ModalBody>
            <ModalFooter p="10px 42px 42px 42px">
              <Box
                onClick={handeModalClose}
                mr="auto"
                color="#5E5E5E"
                fontWeight="600"
                cursor="pointer"
              >
                Cancel
              </Box>
              <Button onClick={handleModalClick} maxWidth="332px" mt="0" iconAfter>
                Send
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </StyledModal>
      <FixedFooter
        name="preferences"
        order={order}
        isDisabled={isDisabled}
        isButtonDisabled={!isFilled}
        back={backRoute}
        next={nextRoute}
        canBeDisabled={order === 2 || order === 3}
        onNextClick={order === 4 ? () => setIsModalOpen(true) : undefined}
      />
      <DevRouting testAction={handleModalClick} />
    </Flex>
  );
}
