import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from 'pages/home';
import CookiePolicy from 'pages/cookies-policy';
import TermsOfUse from 'pages/terms-of-use';
import PrivacyPolicy from 'pages/privacy-policy';
import ThanksYou from 'pages/thank-you';
import { TeamEvaluation } from 'pages/team-evaluation';
import { Technologies } from 'pages/technologies';
import { Qsorting } from 'pages/qsorting';
import { QsortingBig } from 'pages/qsorting-big';
import { Informations } from 'components';
import * as RoutesList from 'common/routes';
import Login from 'pages/login';
import PreferencesScreen from 'pages/preferences';
import StrengthsScreen from 'pages/strengths';
import NameScreen from 'pages/name';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={RoutesList.HOME} element={<Home />} />
          <Route path={RoutesList.PRIVACY} element={<PrivacyPolicy />} />
          <Route path={RoutesList.TERMS} element={<TermsOfUse />} />
          <Route path={RoutesList.COOKIE} element={<CookiePolicy />} />
          <Route path={RoutesList.THANKS} element={<ThanksYou />} />
          <Route path={RoutesList.INFORMATIONS} element={<Informations />} />
          <Route path={RoutesList.LOGIN} element={<Login />} />
          <Route path={RoutesList.NAME} element={<NameScreen />} />
          <Route path={RoutesList.TECH} element={<Technologies />} />
          <Route path={RoutesList.TEAM1} element={<TeamEvaluation order={1} />} />
          <Route path={RoutesList.TEAM2} element={<TeamEvaluation order={2} />} />
          <Route path={RoutesList.TEAM3} element={<TeamEvaluation order={3} />} />
          <Route path={RoutesList.TEAM4} element={<TeamEvaluation order={4} />} />
          <Route path={RoutesList.QSORT1} element={<QsortingBig order={1} />} />
          <Route path={RoutesList.QSORT2} element={<Qsorting order={2} />} />
          <Route path={RoutesList.QSORT3} element={<Qsorting order={3} />} />
          <Route path={RoutesList.QSORT4} element={<Qsorting order={4} />} />
          <Route path={RoutesList.PREFERENCES_SCREEN} element={<PreferencesScreen />} />
          <Route path={RoutesList.STRENGTHS_SCREEN} element={<StrengthsScreen />} />
          <Route path="/:id" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
