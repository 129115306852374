/* eslint-disable no-unused-vars */
export type TPersonalPreferences = {
  description: string;
  id: string;
  name: string;
  strengths?: any;
};

type TWhiteLabelData = {
  logo_text: string;
  logo_url: string;
};

type TTeamStrengths = {
  description: string;
  id: string;
  maximum_choices: number;
  minimum_choices: number;
  name: string;
};

export type TDashboardTeamEntries = {
  id: string;
  team_id: string;
  is_candidate_flow: boolean;
  survey_id: string;
  started_at: string;
  submitted_at: string;
};

export type TDashboardTeam = {
  id: string;
  name: string;
  is_candidate_flow: boolean;
  entries: TDashboardTeamEntries[];
};

type TSingleSkill = {
  name: string;
  custom: boolean;
};

type TSingleAnswer = {
  id: string;
  chosen_at: string;
};

type TSingleQSortAnswer = {
  chosen_at: string;
  id: string;
  name: string;
  tooltip: string;
};

type TSingleQSortAnswerValue = {
  [key: string]: TSingleQSortAnswer;
};

type TTeamInfo = {
  id: string;
  name: string;
  entry_ids: string[];
  is_canditate_flow: boolean;
  company_id: string;
};

type TTeamDetailsTableCounter = {
  count: number;
  team_size: number;
};

type TTeamDetailsTable = {
  most_important_for: TTeamDetailsTableCounter;
  important_for: TTeamDetailsTableCounter;
  strength_declared_by: TTeamDetailsTableCounter;
  insight: string;
};

type TCompanyDetail = {
  id: string;
  name: string;
  team_size: number;
  fulfilled_entries: number;
};

type TSingleCompanyDetail = {
  id: string;
  company_name?: string;
  company_id: string;
  team_size: number;
  fulfilled_entries: number;
  team_name?: string;
};

type TLabels = {
  active: boolean;
  id: string;
  internal: boolean;
  name: string;
  tooltip_description: string;
  tooltip_preference: string;
  tooltip_strength: string;
};

export enum ECategories {
  CLIMATE = 'Climate',
  WORK_STYLE = 'Work style',
  SOFTWARE_DEVELOPMENT = 'Software Development',
  PRODUCT_MANAGEMENT = 'Product Management',
}

export type TStore = {
  survey: {
    answers: {
      skills: {
        value: TSingleSkill[];
      };
      Climate: {
        value: TSingleAnswer[];
      };
      'Software Development': {
        value: TSingleAnswer[];
      };
      'Project Management': {
        value: TSingleAnswer[];
      };
      'Work style': {
        value: TSingleAnswer[];
      };
      'q-Climate': {
        value: TSingleQSortAnswerValue[];
      };
      'q-Software Development': {
        value: TSingleQSortAnswerValue[];
      };
      'q-Project Management': {
        value: TSingleQSortAnswerValue[];
      };
      'q-Work style': {
        value: TSingleQSortAnswerValue[];
      };
      Evaluation: {
        value: {
          id: string;
          chosen_at: string;
        };
      };
      [key: string]: any;
    };
    survey: {
      team_strengths: TTeamStrengths[];
      whitelabel_data: TWhiteLabelData;
      id: string;
      is_candidate_flow: string;
      personal_preferences: TPersonalPreferences[];
    };
    disabledCategories: string[];
  };
  dashboard: {
    loading: boolean;
    company: {
      id: string;
      name: string;
      logo_url: string;
      logo_text: string;
      teams: TDashboardTeam[];
    };
    companyMatch: {
      [key: string]: {
        [key: string]: number;
      };
    };
    companyDetails: TCompanyDetail[];
    companySummary: {
      company_strengths: {
        [key: string]: {
          [key: string]: number;
        };
      };
      teams: {
        [key: string]: {
          team_fit: number;
          team_strengths: {
            [key: string]: number;
          };
        };
      };
    };
    teamTopStrengths: {
      [key: string]: {
        [key: string]: number;
      };
    };
    teamTopPreferences: {
      [key: string]: {
        [key: string]: number;
      };
    };
    teamTableDetails: {
      [key: string]: {
        [key: string]: TTeamDetailsTable;
      };
    };
    companyTableDetails: {
      [key: string]: {
        [key: string]: TTeamDetailsTable;
      };
    };
    teamDetails: TSingleCompanyDetail;
    usersBestTeam: {
      [key: string]: {
        [key: string]: number;
      };
    };
    teamEntries: {
      [key: string]: TDashboardTeamEntries[];
    };
    bestPreferences: {
      [key: string]: string[];
    };
    strengths: {
      [key: string]: {
        [key: string]: number;
      };
    };
    preferences: {
      [key: string]: {
        [key: string]: number;
      };
    };
    fit: number;
    skills: {
      top_five: string[];
      team: {
        base: {
          [key: string]: number;
        };
        custom: {
          [key: string]: number;
        };
      };
      detailed_list: {
        [key: string]: {
          base: string[];
          custom: string[];
        };
      };
    };
    teamMatches: {
      [key: string]: {
        top_matching: {
          [key: string]: {
            [key: string]: number;
          };
        };
        blind_spots: {
          [key: string]: {
            [key: string]: number;
          };
        };
      };
    };
    companyMatches: {
      [key: string]: {
        top_matching: {
          [key: string]: {
            [key: string]: number;
          };
        };
        blind_spots: {
          [key: string]: {
            [key: string]: number;
          };
        };
      };
    };
    teamSurveys: {
      [key: string]: TDashboardTeamEntries[];
    };
    teamFit: {
      [key: string]: number;
    };
    labels: TLabels[];
    companyDashboardInfo: {
      id: string;
      name: string;
      logo_url: string;
      logo_text: string;
      teams: TDashboardTeam[];
    };
    companySkills: {
      company: {
        base: string[];
        custom: string[];
      };
      detailed_list: {
        [key: string]: {
          base: string[];
          custom: string[];
        };
      };
      top_five: string[];
    };
  };
};
