import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { motion } from 'framer-motion';
import { Flex, keyframes, Text } from '@chakra-ui/react';
import Draggable from './Draggable';
import { StyledElements } from './styles';

function Droppable({ element, index, lastColumn, gray }: any) {
  const { isOver, setNodeRef } = useDroppable({
    id: index,
  });
  const style = {
    backgroundColor: index !== 0 && isOver ? '#9e83ec' : undefined,
  };
  const animationKeyframes = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  `;
  const animation = `${animationKeyframes} 3s ease-in-out infinite`;

  return (
    <div ref={setNodeRef} style={style} id={`droppable-${index}`}>
      {lastColumn && (
        <Flex
          justifyContent="center"
          alignItems="center"
          position="absolute"
          width="114px"
          height="114px"
        >
          <Text
            as={motion.div}
            animation={animation}
            textAlign="center"
            color="#8A69EA"
            fontWeight="600"
            fontSize="12px"
          >
            {!isOver && !gray && (
              <>
                drop card <br /> here
              </>
            )}
          </Text>
        </Flex>
      )}
      {index === 0 ? (
        <>
          <Text
            color="#8A69EA"
            fontSize="14px"
            fontWeight="600"
            textAlign="center"
            lineHeight="20px"
            mb="25px"
            mt="20px"
          >
            Drag each card from here
            <br />
            and drop it on a square on the right
          </Text>
          <StyledElements>
            {element.map((elem: any) => (
              <Draggable elem={elem} />
            ))}
          </StyledElements>
        </>
      ) : (
        element.map((elem: any) => <Draggable elem={elem} />)
      )}
    </div>
  );
}

export default Droppable;
