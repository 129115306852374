import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Box, Text, Tooltip } from '@chakra-ui/react';
import { ECategories, TDashboardTeamEntries, TStore } from 'types/general.types';
import { TeamFit } from './TeamFit';

export function MemberPopover({
  preferencesBestTeam,
  usersBestTeams,
  user,
}: {
  preferencesBestTeam: {
    [key: string]: string[];
  };
  usersBestTeams: {
    [key: string]: {
      [key: string]: number;
    };
  };
  user: TDashboardTeamEntries;
}) {
  const labels = useSelector((state: TStore) => state.dashboard.labels);
  const bestTeamByUser = usersBestTeams?.[user?.survey_id];
  const preferencesBestByUser: any = preferencesBestTeam?.[user?.survey_id];
  const hasPreferences = preferencesBestByUser && Object.keys(preferencesBestByUser).length > 0;

  return (
    <Flex justifyContent="space-between" padding="24px">
      <Flex flexDirection="column" width="287px" paddingRight="24px">
        <Box
          marginBottom="16px"
          paddingBottom="16px"
          borderBottom={hasPreferences ? '2px solid #F0EDF9' : '0'}
        >
          <Text fontSize="18px" fontWeight="600" lineHeight="27px">
            {user.survey_id || 'No name'}
          </Text>
        </Box>
        <Box flexDirection="column">
          <Text fontSize="14px" fontWeight="500" lineHeight="20px" mb="7px">
            {hasPreferences && 'Most important preferences'}
          </Text>
          <Flex flexWrap="wrap" marginBottom="16px" paddingBottom="20px">
            {preferencesBestByUser?.[ECategories.CLIMATE]?.map(
              (preference: string, index: number) => (
                <Tooltip
                  label={labels.filter((l) => l.id === preference)[0]?.tooltip_description}
                  hasArrow
                  placement="top"
                  backgroundColor="#000"
                  borderRadius="0"
                  p="12px"
                >
                  <Text
                    background="#FFC068"
                    mr="7px"
                    mb="7px"
                    padding="7px"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="20px"
                    borderRadius="4px"
                    key={index}
                  >
                    {labels.filter((l) => l.id === preference)[0]?.name}
                  </Text>
                </Tooltip>
              ),
            )}
            {preferencesBestByUser?.[ECategories.PRODUCT_MANAGEMENT]?.map(
              (preference: string, index: number) => (
                <Tooltip
                  label={labels.filter((l) => l.id === preference)[0]?.tooltip_description}
                  hasArrow
                  placement="top"
                  backgroundColor="#000"
                  borderRadius="0"
                  p="12px"
                >
                  <Text
                    background="#C393A1"
                    mr="7px"
                    mb="7px"
                    padding="7px"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="20px"
                    borderRadius="4px"
                    key={index}
                  >
                    {labels.filter((l) => l.id === preference)[0]?.name}
                  </Text>
                </Tooltip>
              ),
            )}
            {preferencesBestByUser?.[ECategories.SOFTWARE_DEVELOPMENT]?.map(
              (preference: string, index: number) => (
                <Tooltip
                  label={labels.filter((l) => l.id === preference)[0]?.tooltip_description}
                  hasArrow
                  placement="top"
                  backgroundColor="#000"
                  borderRadius="0"
                  p="12px"
                >
                  <Text
                    background="#B0AC93"
                    mr="7px"
                    mb="7px"
                    padding="7px"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="20px"
                    borderRadius="4px"
                    key={index}
                  >
                    {labels.filter((l) => l.id === preference)[0]?.name}
                  </Text>
                </Tooltip>
              ),
            )}
            {preferencesBestByUser?.[ECategories.WORK_STYLE]?.map(
              (preference: string, index: number) => (
                <Tooltip
                  label={labels.filter((l) => l.id === preference)[0]?.tooltip_description}
                  hasArrow
                  placement="top"
                  backgroundColor="#000"
                  borderRadius="0"
                  p="12px"
                >
                  <Text
                    background="#A4C0CE"
                    mr="7px"
                    mb="7px"
                    padding="7px"
                    fontSize="12px"
                    fontWeight="600"
                    lineHeight="20px"
                    borderRadius="4px"
                    key={index}
                  >
                    {labels.filter((l) => l.id === preference)[0]?.name}
                  </Text>
                </Tooltip>
              ),
            )}
          </Flex>
        </Box>
      </Flex>
      <Flex flexDirection="column" borderLeft="2px solid #F0EDF9" paddingLeft="24px">
        <Text fontSize="16px" fontWeight="600" lineHeight="25.6px" marginBottom="28.5px">
          Possible team for internal mobility
        </Text>
        <Flex flexDirection="column" width="384px">
          {bestTeamByUser &&
            Object.keys(bestTeamByUser)
              .slice(0, 5)
              .map((team: string, index: number) => (
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  borderBottom={index === 4 ? '0' : '2px solid #F0EDF9'}
                  paddingY="12px"
                  key={index}
                >
                  <Text fontSize="14px" fontWeight="400" lineHeight="20px">
                    {team}
                  </Text>
                  <TeamFit fit={bestTeamByUser[team]} />
                </Flex>
              ))}
        </Flex>
      </Flex>
    </Flex>
  );
}
