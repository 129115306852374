import React, { useEffect, useState } from 'react';
import { Flex, Text, useToast, Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Confetti from 'react-confetti';
import { Button, Input, Navbar } from 'components';
import cup from 'assets/demo/cup.svg';
// import copy from 'assets/demo/copy.svg';
import { TStore } from 'types/general.types';
import useWindowDimensions from 'common/hooks';
import http from 'services/http/HttpService';
import { StyledImageWrapper, StyledEmailWrapper } from './styles';

function ThanksYou() {
  const [inputData, setInputData] = useState('');
  const [isInputEmailVisible, setInputEmailVisible] = useState(true);
  const [error, setError] = useState(true);
  const toast = useToast();
  const { height, width } = useWindowDimensions();
  const surveyId = useSelector((state: TStore) => state.survey.survey.id);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const handleCopyClick = () => {
  //   navigator.clipboard.writeText(surveyId);
  //   toast.closeAll();
  //   toast({
  //     duration: 5000,
  //     render: () => (
  //       <Box
  //         fontSize="14px"
  //         color="#000"
  //         p={3}
  //         bg="#CAF9E2"
  //         borderRadius="77px"
  //         textAlign="center"
  //         mb="15px"
  //         fontWeight="600"
  //       >
  //         ID copied to clipboard
  //       </Box>
  //     ),
  //   });
  // };

  const handeSendButtonClick = () => {
    http.post(`/surveys/${surveyId}/email`, { email: inputData }).then((data) => {
      toast.closeAll();
      if (data.status === 200) {
        toast({
          isClosable: true,
          duration: null,
          containerStyle: { width: 500, maxWidth: 500 },
          render: () => (
            <Box
              fontSize="14px"
              color="#000"
              p={3}
              bg="#CAF9E2"
              borderRadius="77px"
              textAlign="center"
              mb="15px"
              fontWeight="600"
            >
              Thank you. Email sent on {inputData}.
            </Box>
          ),
        });
        setInputEmailVisible(false);
      }
    });
  };

  useEffect(() => {
    const expression = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputData);
    if (expression) {
      setError(false);
    } else {
      setError(true);
    }
  }, [inputData]);

  return (
    <Flex flexDirection="column">
      <Navbar top />
      <StyledImageWrapper>
        <Flex
          backgroundColor="#fff"
          position="relative"
          zIndex="9"
          flexDirection="column"
          alignItems="center"
        >
          <img width="91px" height="91px" src={cup} alt="" />
          <Text fontWeight="600" fontSize="24px" mt="14px">
            All done!
          </Text>
          <Text
            fontWeight="500"
            fontSize="16px"
            mt="8px"
            mb="15px"
            textAlign={{ base: 'center', lg: 'left' }}
          >
            <p>Drop us your e-mail, so we can send you the results.</p>
          </Text>
          {/* <Flex
            justifyContent="space-between"
            padding="10px 16px"
            mt="15px"
            height="40px"
            background="#F6F4FC"
            borderRadius="2px"
          >
            <Text fontSize="14px" fontWeight="600" mr="10px">
              {surveyId}
            </Text>
            <img
              style={{ cursor: 'pointer' }}
              width="14px"
              src={copy}
              alt=""
              onClick={handleCopyClick}
            />
          </Flex> */}
          {isInputEmailVisible && (
            <StyledEmailWrapper>
              <Flex
                maxWidth="550px"
                width="100%"
                mt="16px"
                pt="16px"
                borderTop="1px solid #efedf9"
                alignItems="flex-end"
                justifyContent="center"
              >
                <Input
                  label=""
                  placeholder="Your e-mail"
                  background="#fff"
                  data-testid="input-user-email"
                  _focus={{ borderColor: '#8A69EA' }}
                  // eslint-disable-next-line max-len
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setInputData(e.target.value)}
                />
                <Button
                  disabled={error}
                  maxWidth="210px"
                  onClick={() => handeSendButtonClick()}
                  data-testid="button-send"
                >
                  SEND
                </Button>
              </Flex>
              {error ? (
                <Text mt="5px" fontSize="12px">
                  You need an email that looks like this: abc@email.xyz
                </Text>
              ) : (
                <Box height="22px" />
              )}
            </StyledEmailWrapper>
          )}
        </Flex>
        <Confetti
          width={width}
          height={height}
          colors={['#BEAAF8', '#CAF9E2', '#BEAAF8', '#8A69EA', '#EAE3FE']}
        />
      </StyledImageWrapper>
    </Flex>
  );
}

export default ThanksYou;
