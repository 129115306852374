import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import logo from 'assets/brand/logo.svg';

function SpacebridgeFooter() {
  return (
    <Flex justifyContent="center" alignItems="center" pb="150px" pt="70px">
      <Text fontSize="11px" fontWeight={600} mr="11px">
        Powered by:
      </Text>
      <img src={logo} alt="" style={{ height: 14 }} />
    </Flex>
  );
}

export default SpacebridgeFooter;
