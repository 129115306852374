import React from 'react';
import { Flex } from '@chakra-ui/react';
import LoginButton from 'components/LoginButton/LoginButton';
import LogoutButton from 'components/LogoutButton/LogoutButton';

function Login() {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
    >
      <LoginButton />
      <LogoutButton />
    </Flex>
  );
}

export default Login;
