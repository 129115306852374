import {
  Avatar,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { TDashboardTeamEntries, TStore } from 'types/general.types';
import { MemberPopover } from '../../DashboardTeam/components/MemberPopover';
import { StyledAvatars } from '../../DashboardTeam/components/styles';

export default function Members({
  id,
  showAll,
  allItem,
  opacity,
}: {
  id: string | undefined;
  showAll?: boolean;
  allItem?: boolean;
  opacity?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const usersBestTeam = useSelector((state: TStore) => state.dashboard.usersBestTeam);
  const preferencesBestTeam = useSelector((state: TStore) => state.dashboard.bestPreferences);
  const entries = useSelector((state: TStore) => state.dashboard.teamEntries);
  const users = id && entries?.[id];
  const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });

  return (
    <Flex flexDirection="column">
      <Text color="#5E5E5E" fontSize="11px" fontWeight="500" mb="10px">
        TEAM MEMBERS
      </Text>
      <StyledAvatars
        open={showAll || open}
        opacity={opacity}
        onClick={() => setOpen(true)}
        ref={ref}
      >
        {allItem && <Avatar color="#fff" background="#5431B7" icon={<div>All</div>} />}
        {users &&
          users?.map((user: TDashboardTeamEntries, index: number) => (
            <Popover key={index} trigger={showAll || open ? 'hover' : undefined}>
              <PopoverTrigger>
                <Avatar name={user?.survey_id} />
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <MemberPopover
                    preferencesBestTeam={preferencesBestTeam}
                    usersBestTeams={usersBestTeam}
                    user={user}
                  />
                </PopoverBody>
              </PopoverContent>
            </Popover>
          ))}
      </StyledAvatars>
    </Flex>
  );
}

Members.defaultProps = {
  showAll: false,
  allItem: false,
  opacity: false,
};
