import React, { useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Navbar, Container, DevRouting, FixedFooter } from 'components';
import * as RoutesList from 'common/routes';
import { useDispatch, useSelector } from 'react-redux';
import { CreatableSelect } from 'chakra-react-select';
import { StyledTextWrapper, StyledTittleWrapper } from 'components/Experience/styles';
import { headers } from 'common/headers';
import http from 'services/http/HttpService';
import SpacebridgeFooter from 'components/SpacebridgeFooter';
import { updateAnswers } from 'store/rootReducer/reducer';
import { TStore } from 'types/general.types';
import { StyledAutocomplete } from './styles';

export function Technologies() {
  const dispatch = useDispatch();
  const flow = useSelector((state: TStore) => state.survey.survey.is_candidate_flow);
  const selectedSkills = useSelector((state: TStore) => state.survey.answers.skills);
  const selectedLearnSkills = useSelector((state: TStore) => state.survey.answers.learnSkills);
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);
  const formatedSkills = (selectedSkills?.value || []).map((skill: any) => ({
    label: skill.name,
    value: skill.name,
    __isNew__: skill.custom,
  }));
  const formatedLearnSkills = (selectedLearnSkills?.value || []).map((skill: any) => ({
    label: skill.name,
    value: skill.name,
    __isNew__: skill.custom,
  }));
  const [inputText, setInputText] = useState('');
  const [learnInputText, seLearnInputText] = useState('');
  const [suggestions, setSuggetions] = useState([]);
  const getSuggestions = (text: string) => {
    setInputText(text);
    http.get(`skills-dictionary?limit=5&filter=${text.toLowerCase()}`).then((results: any) => {
      const technologyList: any = [];
      results.data.forEach((element: any) => {
        technologyList.push({ label: element, value: element });
      });
      setSuggetions(technologyList);
    });
  };
  const getLearnSuggestions = (text: string) => {
    seLearnInputText(text);
    http.get(`skills-dictionary?limit=5&filter=${text.toLowerCase()}`).then((results: any) => {
      const technologyList: any = [];
      results.data.forEach((element: any) => {
        technologyList.push({ label: element, value: element });
      });
      setSuggetions(technologyList);
    });
  };
  const handleSkillChange = (selectedOptions: any) => {
    const formatedOptions = selectedOptions.map((option: any) => ({
      name: option.label,
      // eslint-disable-next-line no-underscore-dangle
      custom: option.__isNew__ || false,
    }));
    dispatch(
      updateAnswers({
        name: 'skills',
        value: formatedOptions,
        // eslint-disable-next-line comma-dangle
      }),
    );
  };
  const handleLearnSkillChange = (selectedOptions: any) => {
    const formatedOptions = selectedOptions.map((option: any) => ({
      name: option.label,
      // eslint-disable-next-line no-underscore-dangle
      custom: option.__isNew__ || false,
    }));
    dispatch(
      updateAnswers({
        name: 'learnSkills',
        value: formatedOptions,
        // eslint-disable-next-line comma-dangle
      }),
    );
  };
  const isEmpty = selectedSkills?.value?.length < 1;

  return (
    <div>
      <Navbar top nav currentStep={1} navbarImage={companyData?.logo_url} progressValue={20} />
      <Container small>
        <StyledTittleWrapper>
          <h1>{headers.TECH_TITLE}</h1>
        </StyledTittleWrapper>
        <StyledTextWrapper>
          <p>{headers.TECH_DESCRIPTION}</p>
        </StyledTextWrapper>
        <Flex justify="center" align="flex-start" w="full" direction="column">
          <Text mb="32px" mt="5px" fontWeight="500" fontSize="18px" color="#0C0C0C">
            Now, which skills and technologies are you using daily?
          </Text>
          <StyledAutocomplete>
            <CreatableSelect
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              name="colors"
              options={suggestions}
              placeholder="like Java, .Net, React, UX design, DDD..."
              closeMenuOnSelect={false}
              isClearable={false}
              classNamePrefix="react-select"
              onInputChange={(text) => getSuggestions(text)}
              menuIsOpen={Boolean(inputText)}
              formatCreateLabel={(userInput) => `Add: ${userInput}`}
              noOptionsMessage={({ inputValue }) => `Can't add: ${inputValue}`}
              onChange={(selectedOptions) => handleSkillChange(selectedOptions)}
              defaultValue={formatedSkills}
              openMenuOnFocus
              openMenuOnClick
              isMulti
              id="autocomplete-option-1"
              chakraStyles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  bg: 'transparent',
                  px: 2,
                  cursor: 'inherit',
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: 'none',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: '#4A4A4A',
                  fontWeight: 500,
                }),
              }}
            />
          </StyledAutocomplete>
          <Text mb="32px" mt="32px" fontWeight="500" fontSize="18px" color="#0C0C0C">
            And which skills and technologies would you like to learn?
          </Text>
          <StyledAutocomplete>
            <CreatableSelect
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              name="colors"
              options={suggestions}
              placeholder="like Java, .Net, React, UX design, DDD..."
              closeMenuOnSelect={false}
              isClearable={false}
              classNamePrefix="react-select"
              onInputChange={(text) => getLearnSuggestions(text)}
              menuIsOpen={Boolean(learnInputText)}
              formatCreateLabel={(userInput) => `Add: ${userInput}`}
              noOptionsMessage={({ inputValue }) => `Can't add: ${inputValue}`}
              onChange={(selectedOptions) => handleLearnSkillChange(selectedOptions)}
              defaultValue={formatedLearnSkills}
              openMenuOnFocus
              openMenuOnClick
              isMulti
              id="autocomplete-option-2"
              chakraStyles={{
                dropdownIndicator: (provided) => ({
                  ...provided,
                  bg: 'transparent',
                  px: 2,
                  cursor: 'inherit',
                }),
                indicatorSeparator: (provided) => ({
                  ...provided,
                  display: 'none',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: '#4A4A4A',
                  fontWeight: 500,
                }),
              }}
            />
          </StyledAutocomplete>
        </Flex>
        <Flex alignItems="center" justifyContent="center">
          <SpacebridgeFooter />
        </Flex>
      </Container>
      <FixedFooter
        name="strengths"
        order={1}
        back={RoutesList.NAME}
        next={flow ? RoutesList.PREFERENCES_SCREEN : RoutesList.STRENGTHS_SCREEN}
        canBeDisabled={false}
        isDisabled={false}
        isButtonDisabled={isEmpty}
      />
      <DevRouting />
    </div>
  );
}
