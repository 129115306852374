/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { disableCategory } from 'store/rootReducer/reducer';
import { Button } from 'components';
import { StyledModal } from 'pages/qsorting/styles';
import { StyledSwitch } from './styles';

export function FixedFooter({
  name,
  order,
  isDisabled,
  isButtonDisabled,
  back,
  next,
  onNextClick,
  canBeDisabled,
}: {
  name: string;
  order: number;
  isDisabled: boolean;
  isButtonDisabled?: boolean;
  back?: any;
  next: any;
  onNextClick?: () => void;
  canBeDisabled?: boolean;
}) {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCheck = () => {
    setModalOpen(true);
  };

  const handleClickNext = () => {
    if (onNextClick) {
      onNextClick();
    }
    history(next);
  };

  const handleDisableCategory = () => {
    dispatch(
      disableCategory({
        name: `${name}-${order}`,
        isChecked: true,
      }),
    );
    setModalOpen(false);
  };

  const handleCloseButton = () => {
    dispatch(
      disableCategory({
        name: `${name}-${order}`,
        isChecked: false,
      }),
    );
    setModalOpen(false);
  };

  const getCategoryName = () => {
    const categoryName = `${name}-${order}`;
    switch (categoryName) {
      case 'strengths-2':
        return 'Software development';
      case 'strengths-3':
        return 'Product management';
      case 'preferences-2':
        return 'Software development';
      case 'preferences-3':
        return 'Product management';
      default:
        return '';
    }
  };

  const categoryName = getCategoryName();

  return (
    <>
      <Flex
        position="fixed"
        bottom="0"
        height="76px"
        borderTop="1px solid #E3DCFA"
        width="100%"
        background="#fff"
        px="24px"
        alignItems="center"
        zIndex="9999"
      >
        {back && (
          <Button iconBefore secondary m="0" onClick={() => history(back)} mr="auto">
            Back
          </Button>
        )}
        {canBeDisabled && (
          <StyledSwitch>
            <label className="switch">
              <input type="checkbox" checked={isDisabled} onChange={() => handleCheck()} />
              <span className="slider round" />
            </label>
            <Text
              color="#0C0C0C"
              textTransform="uppercase"
              fontSize={{ base: '8px', md: '14px' }}
              fontWeight="600"
              ml="8px"
            >
              This section doesn&apos;t apply to me
            </Text>
          </StyledSwitch>
        )}
        <Button
          iconAfter
          m="0"
          onClick={handleClickNext}
          disabled={!isDisabled ? isButtonDisabled : false}
          data-testid="button-footer-next"
          minWidth={{ base: '24px', md: '330px' }}
          width={{ base: '24px', md: '330px' }}
        >
          Next
        </Button>
      </Flex>
      <StyledModal>
        <Modal isCentered isOpen={isModalOpen} onClose={() => handleCloseButton()}>
          <ModalOverlay />
          <ModalContent maxWidth="600px" borderRadius={0}>
            <ModalBody p="42px 42px 0 42px">
              <Text
                fontSize="24px"
                textAlign="center"
                fontWeight="700"
                mb="22px"
                color="#0C0C0C"
                lineHeight={1}
              >
                Skip {categoryName}?
              </Text>
              <Text fontSize="14px" mb="24px" fontWeight="500">
                This makes sense if the category doesn&apos;t apply to you.
              </Text>
              <hr />
            </ModalBody>
            <ModalFooter p="24px 42px 42px 42px">
              <Box
                onClick={handleCloseButton}
                mr="auto"
                color="#5E5E5E"
                fontWeight="600"
                cursor="pointer"
                width="50%"
                textAlign="center"
                textTransform="uppercase"
              >
                No, don&apos;t skip
              </Box>
              <Button onClick={handleDisableCategory} width="50%" maxWidth="332px" mt="0" iconAfter>
                Yes, skip it
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </StyledModal>
    </>
  );
}

FixedFooter.defaultProps = {
  onNextClick: () => {},
  isButtonDisabled: false,
  canBeDisabled: false,
  back: false,
};
