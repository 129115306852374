import axios, { ResponseType } from 'axios';
import { AuthInterceptor } from './interceptors/Auth.interceptor';

const httpOptions = {
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json' as ResponseType,
};

const http = axios.create(httpOptions);
http.interceptors.request.use(AuthInterceptor);

export default http;
