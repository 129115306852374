import React from 'react';
import { Text } from '@chakra-ui/react';

export function Headline({ children }: { children: React.ReactNode }) {
  return (
    <Text mb="24px" fontSize="18px" fontWeight="500" lineHeight="26.6px" maxWidth="630px">
      {children}
    </Text>
  );
}
