import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'store/store';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import theme from './theme';

const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

root.render(
  <Provider store={store}>
    <ChakraProvider theme={theme}>
      <Global
        styles={`
          @font-face {
            font-family: 'Visby CF';
            font-style: normal;
            font-weight: 100;
            font-display: swap;
            src: url('/visby/visby-cf-thin.eot');
            src: url('/visby/visby-cf-thin.eot?#iefix') format('embedded-opentype'),
                      url('/visby/visby-cf-thin.woff2') format('woff2'),
                url('/visby/visby-cf-thin.woff') format('woff'),
                url('/visby/visby-cf-thin.ttf') format('truetype'),
                url('/visby/visby-cf-thin.svg#youworkforthem') format('svg');
          }
          
          @font-face {
            font-family: 'Visby CF';
            font-display: swap;
            src: url('/visby/visby-cf.eot');
            src: url('/visby/visby-cf.eot?#iefix') format('embedded-opentype'),
                      url('/visby/visby-cf.woff2') format('woff2'),
                url('/visby/visby-cf.woff') format('woff'),
                url('/visby/visby-cf.ttf') format('truetype'),
                url('/visby/visby-cf.svg#youworkforthem') format('svg');
            font-weight: 400;
            font-style: normal;
          }
          
          @font-face {
            font-family: 'Visby CF';
            font-style: normal;
            font-weight: 500;
            font-display: swap;
            src: url('/visby/visby-cf-medium.eot');
            src: url('/visby/visby-cf-medium.eot?#iefix') format('embedded-opentype'),
                      url('/visby/visby-cf-medium.woff2') format('woff2'),
                url('/visby/visby-cf-medium.woff') format('woff'),
                url('/visby/visby-cf-medium.ttf') format('truetype'),
                url('/visby/visby-cf-medium.svg#youworkforthem') format('svg');
          }

          @font-face {
            font-family: 'Visby CF';
            font-style: normal;
            font-weight: 600;
            font-display: swap;
            src: url('/visby/visby-cf-demi-bold.eot');
            src: url('/visby/visby-cf-demi-bold.eot?#iefix') format('embedded-opentype'),
                      url('/visby/visby-cf-demi-bold.woff2') format('woff2'),
                url('/visby/visby-cf-demi-bold.woff') format('woff'),
                url('/visby/visby-cf-demi-bold.ttf') format('truetype'),
                url('/visby/visby-cf-demi-bold.svg#youworkforthem') format('svg');
          }
          
          @font-face {
            font-family: 'Visby CF';
            font-style: normal;
            font-weight: 700;
            font-display: swap;
            src: url('/visby/visby-cf-bold.eot');
            src: url('/visby/visby-cf-bold.eot?#iefix') format('embedded-opentype'),
                      url('/visby/visby-cf-bold.woff2') format('woff2'),
                url('/visby/visby-cf-bold.woff') format('woff'),
                url('/visby/visby-cf-bold.ttf') format('truetype'),
                url('/visby/visby-cf-bold.svg#youworkforthem') format('svg');
          }

          body {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-family: 'Visby CF';
            margin: 0;
            background: #fff;
            color: #000;
          }

          #chakra-modal-modal {
            border-radius: 0;
            max-width: 600px;
            width: 100%;
        
            .chakra-modal__body {
              padding: 42px;
              background: #fff;
            }

            span {
              font-weight: 600;
            }

            .chakra-button {
              width: 210px;
              height: 56px;
              margin: 50px auto 0 auto;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0;
            }
          }

          .chakra-progress .chakra-progress__track {
            stroke: #282828;
          }

          .chakra-progress .chakra-progress__indicator {
            stroke-linecap: round;
            stroke: #D4C7F8;
          }

          .chakra-popover__content {
            width: 757px !important;
          }

          div .progress {
            background: #fff;
            transition: all 0.3s ease;
            z-index: 999;
          }

          div .progress > div {
            background: #8A69EA;
          }
        `}
      />
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <PersistGate loading={null} persistor={persistor}>
        <Auth0Provider
          domain={domain || ''}
          clientId={clientId || ''}
          redirectUri={`${window.location.origin}/dashboard/71396c89-e694-4fb4-986e-595f407eb2ef`}
        >
          <App />
        </Auth0Provider>
      </PersistGate>
    </ChakraProvider>
  </Provider>,
);
