import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Flex, Text } from '@chakra-ui/react';
import * as RoutesList from 'common/routes';
import { Button, DevRouting, Navbar } from 'components';
import image from 'assets/icons/strengths-screen.svg';
import { TStore } from 'types/general.types';

function StrengthsScreen() {
  const history = useNavigate();
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);

  const handleBackButtonClick = () => {
    history(RoutesList.TECH);
  };

  const handleNextButtonClick = () => {
    history(RoutesList.TEAM1);
  };

  return (
    <div>
      <Navbar top nav currentStep={2} navbarImage={companyData?.logo_url} progressValue={30} />
      <Flex flexDirection="column" textAlign="center">
        <Flex maxWidth="212px" width="100%" margin="150px auto 32px auto">
          <img src={image} alt="img" />
        </Flex>
        <Text fontWeight="700" fontSize="22px" lineHeight="32px" color="#0C0C0C" mb="10px">
          Your team’s strenghts
        </Text>
        <Text fontWeight="500" fontSize="16px" color="#0C0C0C">
          Please describe what makes your team strong
        </Text>
        <Flex maxWidth="590px" width="100%" margin="52px auto 0 auto">
          <Button iconBefore secondary marginRight="60px" onClick={handleBackButtonClick}>
            Back
          </Button>
          <Button
            iconAfter
            minWidth={{ base: 'fit-content', md: '100%' }}
            maxWidth={{ base: 'fit-content', md: 'inherit' }}
            onClick={handleNextButtonClick}
            data-testid="button-next"
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <DevRouting />
    </div>
  );
}

export default StrengthsScreen;
