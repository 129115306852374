import styled from '@emotion/styled';

export const StyledBadge = styled.div<{ order: number }>`
  padding: 6px 10px;
  font-weight: 600;
  font-size: 18px;
  max-width: fit-content;
  margin-top: 80px;
  margin-bottom: 8px;
  
  ${(props: any) =>
    props.order === 1 &&
    `
    background: #FFEEB3;
  `}
  ${(props: any) =>
    props.order === 2 &&
    `
    background: #EAE6DC;
  `}
  ${(props: any) =>
    props.order === 3 &&
    `
    background: #F2E6E3;
  `}
  ${(props: any) =>
    props.order === 4 &&
    `
    background: #DCEBEF;
  `}
`;

export const StyledDescription = styled.div`
  font-weight: 400;
  font-size: 20px;
  color: #0C0C0C;
  margin-bottom: 38px;
`;
