import { Tooltip } from '@chakra-ui/react';
import React, { ReactNode } from 'react';
import { StyledStrengthTag } from './styles';

export function StrengthTag({
  children,
  background,
  space,
  small,
  border,
  borderRadius,
  tooltip,
}: {
  children: ReactNode;
  background?: string;
  space?: boolean;
  small?: boolean;
  border?: string;
  borderRadius?: string;
  tooltip?: string;
}) {
  return (
    <Tooltip label={tooltip} hasArrow placement="top" backgroundColor="#000" borderRadius="0" p="12px">
      <StyledStrengthTag
        background={background}
        space={space}
        small={small}
        border={border}
        borderRadius={borderRadius}
      >
        {children}
      </StyledStrengthTag>
    </Tooltip>
  );
}

StrengthTag.defaultProps = {
  background: '',
  space: false,
  small: false,
  border: '',
  borderRadius: '',
  tooltip: '',
};
