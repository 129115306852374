import React from 'react';
import { Input as ChakraInput, Box, Text } from '@chakra-ui/react';

export function Input({
  label,
  placeholder,
  onChange,
  ...props
}: {
  label: string;
  placeholder: string;
  onChange: any;
  [x: string]: unknown;
}) {
  return (
    <Box>
      <Text mb="8px" fontWeight="600" fontSize="14px">
        {label}
      </Text>
      <ChakraInput
        placeholder={placeholder}
        padding="18.5px 24px"
        color="#4A4A4A"
        border="1px solid"
        borderColor="#DCD1F2"
        borderRadius={0}
        width={{ base: '300px', lg: '420px' }}
        height="56px"
        onChange={onChange}
        {...props}
      />
    </Box>
  );
}
