import styled from '@emotion/styled';

export const StyledHomeLeftWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const StyledHome = styled.div<{ backgroundImage: string }>`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-position: right;
  min-height: 100vh;
`;

export const StyledHomeRightWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 455px;

  @media (max-width: 768px) {
    display: none;
  }
`;
