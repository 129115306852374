import styled from '@emotion/styled';

export const StyledSwitch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-direction: row;
  margin-left: auto;
  margin-right: 40px;

  .details {
    font-weight: 400;
    font-size: 12px;
    margin-top: 5px;
    background: -webkit-linear-gradient(transparent, transparent),
      -webkit-linear-gradient(top, rgba(218, 165, 32, 1) 0%, rgba(213, 173, 109, 1) 5%, rgba(
              226,
              186,
              120,
              1
            )
            10%, rgba(163, 126, 67, 1) 35%, rgba(212, 175, 55, 1) 61%, rgba(213, 173, 109, 1) 100%);
    background: -o-linear-gradient(transparent, transparent);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
  }

  input:checked + .slider {
    background: #8a69ea;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    transform: translateX(14px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;
