import { createSlice, current } from '@reduxjs/toolkit';

export const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    survey: {},
    userId: '',
    answers: {
      skills: {
        value: [],
      },
      learnSkills: {
        value: [],
      },
      Climate: {
        value: [],
      },
      'Software Development': {
        value: [],
      },
      'Product Management': {
        value: [],
      },
      'Work style': {
        value: [],
      },
      'q-Climate': {
        value: [],
      },
      'q-Software Development': {
        value: [],
      },
      'q-Product Management': {
        value: [],
      },
      'q-Work style': {
        value: [],
      },
      Evaluation: '',
    },
    disabledCategories: [],
  },
  reducers: {
    updateSurvey: (state, action) => {
      state.survey = action.payload;
    },
    updateUserId: (state, action) => {
      state.userId = action.payload;
    },
    disableCategory: (state: any, action: any) => {
      if (action.payload.isChecked) {
        state.disabledCategories = [...state.disabledCategories, action.payload.name];
      } else {
        state.disabledCategories = state.disabledCategories.filter(
          (category: any) => category !== action.payload.name,
        );
      }
    },
    updateAnswers: (state: any, action) => {
      state.answers[action.payload.name] = {
        value: action.payload.value,
        chosenAt: action.payload.chosenAt,
      };
    },
    changeAnswers: (state: any, action) => {
      const currentState: any = current(state);
      const newState = [...currentState.answers[action.payload.name].value];
      const indexNumbers = newState.map((item, index) => {
        if (item.find((itemB: any) => itemB.id === action.payload.value.element)) {
          return index;
        }
        return null;
      });
      const filteredIndexNumber = indexNumbers.filter((number) => number !== null || number === 0);
      const sourceIndex: any = filteredIndexNumber[0];
      const element = newState[sourceIndex].filter(
        (item: any) => item.id === action.payload.value.element,
      );
      const sourceArray = newState[sourceIndex].filter(
        (item: any) => item.id !== action.payload.value.element,
      );
      const movedElement = element[0];
      const datedElement = {
        ...movedElement,
        chosen_at: new Date().toISOString(),
      };
      if (sourceIndex === action.payload.value.destination) {
        return;
      }
      if (
        newState[action.payload.value.destination].length > 0 &&
        action.payload.value.destination !== 0
      ) {
        const currentElement = newState[action.payload.value.destination][0];
        newState[sourceIndex] = [...sourceArray, currentElement];
        newState[action.payload.value.destination] = [datedElement];
      } else {
        const destinationArray = [...newState[action.payload.value.destination], datedElement];
        newState[sourceIndex] = sourceArray;
        newState[action.payload.value.destination] = destinationArray;
      }
      state.answers[action.payload.name] = {
        value: newState,
        chosenAt: action.payload.chosenAt,
      };
    },
  },
});

export const { updateSurvey, updateUserId, updateAnswers, changeAnswers, disableCategory } =
  surveySlice.actions;

export default surveySlice.reducer;
