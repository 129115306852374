export const HOME = '/';
export const PRIVACY = '/privacy-policy';
export const TERMS = '/terms-of-use';
export const COOKIE = '/cookies-policy';
export const THANKS = '/thank-you';
export const NAME = '/name';
export const TECH = '/technologies';
export const EXP = '/experience';
export const LOGIN = '/login';
export const INFORMATIONS = '/informations';
export const QSORT1 = '/q-sort-1';
export const QSORT2 = '/q-sort-2';
export const QSORT3 = '/q-sort-3';
export const QSORT4 = '/q-sort-4';
export const TEAM1 = '/team-evaluation-1';
export const TEAM2 = '/team-evaluation-2';
export const TEAM3 = '/team-evaluation-3';
export const TEAM4 = '/team-evaluation-4';
export const PREFERENCES_SCREEN = '/preferences';
export const STRENGTHS_SCREEN = '/strengths';
export const DASHBOARD_TEAM = (id: string) => `/dashboard/team/${id}`;
export const DASHBOARD_TEAM_URL = '/dashboard/team/:id';
export const DASHBOARD_COMPANY = (id: string) => `/dashboard/company/${id}`;
export const DASHBOARD_COMPANY_URL = '/dashboard/company/:id';
export const DASHBOARD = '/dashboard';
export const DASHBOARD_URL = '/dashboard/:id';
export const DASHBOARD_DETAILS_URL = '/dashboard/team/:id/details/:name';
export const DASHBOARD_DETAILS = (id: string, name: string) =>
  `/dashboard/team/${id}/details/${name}`;
export const DASHBOARD_COMPANY_DETAILS_URL = '/dashboard/company/:id/details/:name';
export const DASHBOARD_COMPANY_DETAILS = (id: string, name: string) =>
  `/dashboard/company/${id}/details/${name}`;
export const DASHBOARD_TEAM_PREVIEW = (id: string) => `/team/preview/${id}`;
export const DASHBOARD_TEAM_PREVIEW_URL = '/team/preview/:id';
export const DASHBOARD_DETAILS_PREVIEW = (id: string, name: string) =>
  `/team/preview/${id}/details/${name}`;
export const DASHBOARD_DETAILS_PREVIEW_URL = '/team/preview/:id/details/:name';
