import React, { ReactNode, useRef } from 'react';
import { Flex } from '@chakra-ui/react';

export function AnimatedCard({
  children,
  ...props
}: {
  children: ReactNode;
  [x: string]: unknown;
}) {
  const card = useRef<any>();

  const onMouseMove = ({ clientX, clientY }: { clientX: number; clientY: number }) => {
    const { top, left } = card.current.getBoundingClientRect();
    const { clientWidth, clientHeight } = card.current;
    const mouseXpercentage = Math.round(((clientX - left) / clientWidth) * 100);
    const mouseYpercentage = Math.round(((clientY - top) / clientHeight) * 100);
    card.current.style.background = `radial-gradient(${
      clientHeight * 2
    }px circle at ${mouseXpercentage}% ${mouseYpercentage}%, rgba(255,255,255,0.1), transparent)`;
  };

  const onMouseLeave = () => {
    card.current.style.background = '#000';
  };

  return (
    <Flex
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseLeave}
      ref={card}
      transition="0.3s ease"
      {...props}
    >
      {children}
    </Flex>
  );
}
