import React from 'react';
import { Tooltip } from '@chakra-ui/react';
import { tooltips, tooltipsTeamFit } from 'common/tootlips';
import { StyledTeamFit } from './styles';

export function TeamFit({ big, fit = 0, team }: { big?: boolean; fit?: number; team?: boolean }) {
  return (
    <Tooltip
      label={team ? tooltipsTeamFit[`TOOLTIP_FIT_${fit}`] : tooltips[`TOOLTIP_FIT_${fit}`]}
      hasArrow
      placement="top"
      backgroundColor="#000"
      borderRadius="0"
      p="12px"
    >
      <StyledTeamFit big={big}>
        <div className={`line-icon line-icon-${fit}`}>
          <div className="line line-1" />
          <div className="line line-2" />
          <div className="line line-3" />
          <div className="line line-4" />
        </div>
      </StyledTeamFit>
    </Tooltip>
  );
}

TeamFit.defaultProps = {
  big: false,
  fit: 0,
  team: false,
};
