/* eslint-disable max-len */
/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Navbar, Container, FixedFooter, DevRouting } from 'components';
import background from 'assets/backgrounds/dots.svg';
import arrowLeft from 'assets/icons/arrow2.svg';
import * as RoutesList from 'common/routes';
import { changeAnswers, updateAnswers } from 'store/rootReducer/reducer';
import { TStore } from 'types/general.types';
import { headers } from 'common/headers';
import useWindowDimensions from 'common/hooks';
import { StyledBadge, StyledDescription } from 'pages/team-evaluation/styles';
import SpacebridgeFooter from 'components/SpacebridgeFooter';
import { StyledArrowLeft, StyledBackground, StyledBottom, StyledPyramid } from './styles';
import MobileQsortingBig from './mobileBig';
import Droppable from './Droppable';

export function QsortingBig({ order }: { order: number }) {
  const size = useWindowDimensions();
  const dispatch = useDispatch();
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);
  const flow = useSelector((state: TStore) => state.survey.survey.is_candidate_flow);
  const answers = useSelector((state: TStore) => state.survey.answers);
  const qsortingData = useSelector((state: TStore) => state.survey.survey.personal_preferences);
  const disabledCategories = useSelector((state: TStore) => state.survey.disabledCategories);
  const currentQsortingData = qsortingData[order - 1];
  const { name } = currentQsortingData;
  const datedStrengths = currentQsortingData.strengths.map((item: any) => ({
    chosen_at: new Date().toISOString(),
    ...item,
  }));
  const isDisabled = disabledCategories.includes(`preferences-${order}`);
  const isFilled = answers[`q-${name}`]?.value[0]?.length === 0;

  const getNextRoute = () => {
    if (order === 1) {
      return RoutesList.QSORT2;
    }
    if (order === 2) {
      return RoutesList.QSORT3;
    }
    if (order === 3) {
      return RoutesList.QSORT4;
    }
    if (order === 4) {
      return RoutesList.THANKS;
    }
    return '';
  };

  const getBackRoute = () => {
    if (order === 1) {
      if (flow) {
        return RoutesList.TECH;
      }
      return RoutesList.PREFERENCES_SCREEN;
    }
    if (order === 2) {
      return RoutesList.QSORT1;
    }
    if (order === 3) {
      return RoutesList.QSORT2;
    }
    if (order === 4) {
      return RoutesList.QSORT3;
    }
    return '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const answersLabel = answers[`q-${name}`]?.value;
    const newState = [datedStrengths, [], [], [], [], [], [], [], [], [], [], [], [], [], []];
    if (answersLabel.length) {
      dispatch(
        updateAnswers({
          name: `q-${name}`,
          value: answersLabel,
        }),
      );
    } else {
      dispatch(
        updateAnswers({
          name: `q-${name}`,
          value: newState,
        }),
      );
    }
  }, [order]);

  const handleDragEnd = (result: any) => {
    const destination = result.over.id;
    const element = result.active.id;

    dispatch(
      changeAnswers({
        name: `q-${name}`,
        value: {
          destination,
          element,
        },
      }),
    );
  };

  const sensors = useSensors(useSensor(MouseSensor));
  const nextRoute = getNextRoute();
  const backRoute = getBackRoute();

  return (
    <Flex flexDirection="column">
      <Navbar top nav currentStep={3} navbarImage={companyData?.logo_url} progressValue={70} />
      {size.width < 1050 ? (
        <MobileQsortingBig
          currentQsortingData={currentQsortingData}
          answers={answers[`q-${name}`]?.value}
        />
      ) : (
        <Flex
          flexDirection="column"
          height="calc(100vh - 67px)"
          pl="330px"
          pointerEvents={isDisabled ? 'none' : 'auto'}
        >
          <Container small>
            <StyledBadge order={order}>{headers[`QSORT_HEADER_${order}`]}</StyledBadge>
            <StyledDescription>
              <p>{headers[`QSORT_DESCRIPTION_${order}`]}</p>
            </StyledDescription>
          </Container>
          <DndContext onDragEnd={handleDragEnd} sensors={sensors}>
            <StyledBackground background={background} />
            <StyledPyramid>
              <StyledArrowLeft>
                <img src={arrowLeft} alt="" />
              </StyledArrowLeft>
              {answers[`q-${name}`]?.value.map((element: any, index: number) => (
                <div className={`element-${index}`} key={index}>
                  <Droppable
                    element={element}
                    index={index}
                    lastColumn={index > answers[`q-${name}`].value.length - 3}
                  />
                </div>
              ))}
            </StyledPyramid>
          </DndContext>
          <StyledBottom>
            <Container qsort>
              <Flex justifyContent="space-between" mt="40px">
                <Flex color="#5431B7" fontSize="13px" fontWeight="600">
                  LESS IMPORTANT
                </Flex>
                <Flex color="#5431B7" fontSize="13px" fontWeight="600">
                  IMPORTANT
                </Flex>
                <Flex color="#5431B7" fontSize="13px" fontWeight="600">
                  VERY IMPORTANT
                </Flex>
              </Flex>
            </Container>
          </StyledBottom>
          <SpacebridgeFooter />
        </Flex>
      )}
      <FixedFooter
        name="preferences"
        order={order}
        isDisabled={isDisabled}
        isButtonDisabled={!isFilled}
        back={backRoute}
        next={nextRoute}
        canBeDisabled={order === 2 || order === 3}
      />
      <DevRouting />
    </Flex>
  );
}
