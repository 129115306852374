import styled from '@emotion/styled';

export const StyledPyramid = styled.div<{ gray?: boolean }>`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin: 20px auto 0 auto;
  z-index: 9;

  [class^='element'] {
    border-radius: 2px;
    > div {
      height: 100%;
    }
  }

  .element-0 {
    position: absolute;
    left: 0;
    top: 67px;
    width: 330px;
    border-right: 1px solid #eee;
    background-color: #fff;
    padding: 15px;
    height: 100%;
  }

  .element-1 {
    height: 116px;
    width: 116px;
    grid-area: 3 / 1 / 4 / 2;
    background-color: #eae3fe;
    border: 1px solid #beaaf8;
  }

  .element-2 {
    height: 116px;
    width: 116px;
    grid-area: 2 / 2 / 3 / 3;
    background-color: #f6f4fc;
    border: 1px solid #beaaf8;
  }

  .element-3 {
    height: 116px;
    width: 116px;
    grid-area: 3 / 2 / 4 / 3;
    background-color: #f6f4fc;
    border: 1px solid #beaaf8;
  }

  .element-4 {
    height: 116px;
    width: 116px;
    grid-area: 1 / 3 / 2 / 4;
    background-color: #fff;
    border: 1px solid #beaaf8;
  }

  .element-5 {
    height: 116px;
    width: 116px;
    grid-area: 2 / 3 / 3 / 4;
    background-color: #fff;
    border: 1px solid #beaaf8;
  }

  .element-6 {
    height: 116px;
    width: 116px;
    grid-area: 3 / 3 / 4 / 4;
    background-color: #fff;
    border: 1px solid #beaaf8;
  }

  .element-7 {
    height: 116px;
    width: 116px;
    grid-area: 2 / 4 / 3 / 5;
    background-color: #f6f4fc;
    border: 1px solid #beaaf8;
  }

  .element-8 {
    height: 116px;
    width: 116px;
    grid-area: 3 / 4 / 4 / 5;
    background-color: #f6f4fc;
    border: 1px solid #beaaf8;
  }

  .element-9 {
    height: 116px;
    width: 116px;
    grid-area: 3 / 5 / 4 / 6;
    background-color: #d5caf7;
    border: 1px solid #d5caf7;
  }

  ${(props) =>
    props.gray &&
    `
    [class^='element'] {
      border-color: #D2D2D2;
      background-color: #fff;

      [role="button"] > div {
        border-color: #D2D2D2;
        background-color: #fff;
        color: #D2D2D2;
      }

      p {
        color: #D2D2D2;
      }
    }
  `}
`;

export const StyledBackground = styled.div`
  position: absolute;
  left: 0;
  top: 280px;
  background-image: url('${({ background }: { background: string }) => background}');
  width: 100%;
  height: 450px;
`;

export const StyledArrowRight = styled.div`
  position: absolute;
  left: 12%;
  top: 330px;
`;

export const StyledArrowLeft = styled.div`
  grid-area: 1/5/3/5;
`;

export const StyledSidebar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 99;
`;

export const StyledElements = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  padding-bottom: 80px;

  > div {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const StyledBottom = styled.div`
  background-color: #fff;
  position: relative;
`;

export const StyledModal = styled.div``;

export const StyledMobile = styled.div`
  font-family: sans-serif;
  text-align: center;
`;

export const StyledPyramidMobile = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  z-index: 9;

  [class^='div'] {
    height: 80px;
    width: 80px;
    border: 1px solid #beaaf8;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div1 {
    grid-area: 3 / 1 / 4 / 2;
    background-color: #eae3fe;
  }

  .div2 {
    grid-area: 2 / 2 / 3 / 3;
    background-color: #f6f4fc;
  }

  .div3 {
    grid-area: 3 / 2 / 4 / 3;
    background-color: #f6f4fc;
  }
  .div4 {
    grid-area: 1 / 3 / 2 / 4;
    background-color: #fff;
  }

  .div5 {
    grid-area: 2 / 3 / 3 / 4;
    background-color: #fff;
  }

  .div6 {
    grid-area: 3 / 3 / 4 / 4;
    background-color: #fff;
  }

  .div7 {
    grid-area: 2 / 4 / 3 / 5;
    background-color: #f6f4fc;
  }

  .div8 {
    grid-area: 3 / 4 / 4 / 5;
    background-color: #f6f4fc;
  }

  .div9 {
    grid-area: 3 / 5 / 4 / 6;
    background-color: #eae3fe;
  }

  .square {
    aspect-ratio: 1 / 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eee;
    font-size: 12px;
  }
`;

export const StyledBackgroundMobile = styled.div`
  background-image: url('${({ background }: { background: string }) => background}');
  position: absolute;
  left: 0;
  top: 280px;
  width: 100%;
  height: 385px;
`;

export const StyledResultMobile = styled.div`
  background-color: #8a69ea;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
