import styled from '@emotion/styled';

export const StyledTableWrapper = styled.div`
  margin-bottom: 77px;

  th {
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    color: #5e5e5e;
    letter-spacing: 0.1px;
  }

  td {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #0c0c0c;
    text-align: center;

    &:first-of-type {
      text-align: left;
    }
  }

  tr {
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: #f7f6fa;
    }
  }

  tr:last-child td {
    border: 0;
  }

  span {
    color: #a4a4a4;
  }
`;
