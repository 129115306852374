/* eslint-disable react/no-danger */
import React, { ReactNode } from 'react';
import { Flex, Text, Tooltip } from '@chakra-ui/react';
import * as RoutesList from 'common/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import arrow3 from 'assets/icons/arrow3.svg';
import womanImage from 'assets/icons/woman.svg';
import { StyledCard } from './styles';

export function DashboardCard({
  children,
  blindSpot,
  noBlindSpot,
  noMatch,
  id,
  name,
}: {
  children: ReactNode;
  blindSpot?: boolean;
  noBlindSpot?: boolean;
  noMatch?: boolean;
  id: string;
  name: string;
}) {
  const { pathname } = useLocation();
  const history = useNavigate();
  const urlName = name.replace(' ', '-').toLowerCase();
  const isPreview = pathname.includes('preview');

  const handleDetailedClick = () => {
    if (isPreview) {
      history(RoutesList.DASHBOARD_DETAILS_PREVIEW(id, urlName));
    } else {
      history(RoutesList.DASHBOARD_DETAILS(id, urlName));
    }
  };

  if (noBlindSpot) {
    return (
      <StyledCard empty>
        <Flex justifyContent="center" alignItems="center" flexDir="column" height="100%">
          <img src={womanImage} alt="" />
          <Text color="#5E5E5E" fontWeight="600" mt="5px">
            No potential weaknesses
          </Text>
        </Flex>
      </StyledCard>
    );
  }
  if (noMatch) {
    return (
      <StyledCard>
        <Flex
          justifyContent="space-between"
          padding="17px 0"
          borderRadius="4px"
          borderBottom="1px solid #F7F6FA"
        >
          <Flex alignItems="center">
            <Text fontSize="11px" fontWeight="500" lineHeight="17.5px" mr="5px">
              {blindSpot ? 'TEAM’S POTENTIAL WEAKNESSES' : 'TEAM’S TRUE STRENGHTS'}
            </Text>
            <Tooltip
              label={
                blindSpot
                  ? 'These are the attributes people on the team individually care about but can&apos;t find among team&apos;s strengths. Follow-up is advised.'
                  : 'These are the attributes people on the team individually care about and which are highly present among strenghts of the team.'
              }
              hasArrow
              placement="top"
              backgroundColor="#000"
              borderRadius="0"
              p="12px"
            >
              <Text color="#D4C7F8" fontSize="13px" cursor="pointer">
                ?
              </Text>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex justifyContent="center" alignItems="center" height="calc(100% - 45px)">
          <Text color="#D4C7F8">No data on true strenghts</Text>
        </Flex>
      </StyledCard>
    );
  }
  return (
    <StyledCard>
      <Flex
        justifyContent="space-between"
        padding="17px 0"
        borderRadius="4px"
        borderBottom="1px solid #F7F6FA"
      >
        <Flex alignItems="center">
          <Text fontSize="11px" fontWeight="500" lineHeight="17.5px" mr="5px">
            {blindSpot ? 'TEAM’S POTENTIAL WEAKNESSES' : 'TEAM’S TRUE STRENGHTS'}
          </Text>
          <Tooltip
            label={
              blindSpot
                ? 'These are the attributes people on the team individually care about but can&apos;t find among team&apos;s strengths. Follow-up is advised.'
                : 'These are the attributes people on the team individually care about and which are highly present among strenghts of the team.'
            }
            hasArrow
            placement="top"
            backgroundColor="#000"
            borderRadius="0"
            p="12px"
          >
            <Text color="#D4C7F8" fontSize="13px" cursor="pointer">
              ?
            </Text>
          </Tooltip>
        </Flex>
        {!blindSpot && (
          <Flex alignItems="center" cursor="pointer">
            <Text
              color="#5431B7"
              fontSize="12px"
              fontWeight="600"
              lineHeight="20px"
              mr="4px"
              onClick={handleDetailedClick}
            >
              Detailed analysis
            </Text>
            <img height="8" width="10" src={arrow3} alt="" />
          </Flex>
        )}
      </Flex>
      {children}
    </StyledCard>
  );
}

DashboardCard.defaultProps = {
  blindSpot: false,
  noBlindSpot: false,
  noMatch: false,
};
