export const tooltips: any = {
  TOOLTIP_FIT_0:
    'There is a very low match, means that almost no-one believe this is a strength of your team.',
  TOOLTIP_FIT_1:
    'There is a low fit which means that few of your team members believe this is a strength of your team and for the few of them this is a priority',
  TOOLTIP_FIT_2:
    'There is a moderate fit which means that some of your team members believe this is a strength of your team and for some of them this is a priority',
  TOOLTIP_FIT_3:
    'There is a significant fit which means that most of your team members believe this is a strength of your team and for most of them this is a priority',
  TOOLTIP_FIT_4:
    'There is a high fit which means that almost all of your team members believe this is a strength of your team and for almost all of them this is a priority',
};

export const tooltipsTeamFit: any = {
  TOOLTIP_FIT_0:
    'Your team’s overall fit measured against strengths vs. preferences. The more bars in blue, the higher the fit.',
  TOOLTIP_FIT_1:
    'Your team’s overall fit measured against strengths vs. preferences. The more bars in blue, the higher the fit.',
  TOOLTIP_FIT_2:
    'Your team’s overall fit measured against strengths vs. preferences. The more bars in blue, the higher the fit.',
  TOOLTIP_FIT_3:
    'Your team’s overall fit measured against strengths vs. preferences. The more bars in blue, the higher the fit.',
  TOOLTIP_FIT_4:
    'Your team’s overall fit measured against strengths vs. preferences. The more bars in blue, the higher the fit.',
};
