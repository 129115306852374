import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Flex, Text } from '@chakra-ui/react';
import * as RoutesList from 'common/routes';
import { Button, DevRouting, Navbar } from 'components';
import image from 'assets/icons/prefereces-screen.svg';
import { TStore } from 'types/general.types';

function PreferencesScreen() {
  const flow = useSelector((state: TStore) => state.survey.survey.is_candidate_flow);
  const companyData = useSelector((state: TStore) => state.survey.survey.whitelabel_data);
  const history = useNavigate();

  const handleBackButtonClick = () => {
    if (flow) {
      history(RoutesList.TECH);
    } else {
      history(RoutesList.TEAM4);
    }
  };

  const handleNextButtonClick = () => {
    history(RoutesList.QSORT1);
  };

  return (
    <div>
      <Navbar top nav currentStep={3} navbarImage={companyData?.logo_url} progressValue={70} />
      <Flex flexDirection="column" textAlign="center">
        <Flex maxWidth="212px" width="100%" margin="150px auto 32px auto">
          <img src={image} alt="img" />
        </Flex>
        <Text fontWeight="700" fontSize="22px" lineHeight="32px" color="#0C0C0C" mb="10px">
          Your preferences
        </Text>
        <Text fontWeight="500" fontSize="16px" color="#0C0C0C">
          Please describe what matters to you in your daily work
        </Text>
        <Flex maxWidth="590px" width="100%" margin="52px auto 0 auto">
          <Button iconBefore secondary marginRight="60px" onClick={handleBackButtonClick}>
            Back
          </Button>
          <Button
            iconAfter
            minWidth={{ base: 'fit-content', md: '100%' }}
            onClick={handleNextButtonClick}
            data-testid="button-next"
            maxWidth={{ base: 'fit-content', md: 'inherit' }}
          >
            Next
          </Button>
        </Flex>
      </Flex>
      <DevRouting />
    </div>
  );
}

export default PreferencesScreen;
